import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js";
import {EXRLoader} from "three/addons/loaders/EXRLoader.js";

import * as THREE from "three";

export default class Assets {
    static instance = null; // Static instance for singleton pattern

    constructor() {
        // Singleton pattern implementation
        if (Assets.instance) {
            return Assets.instance;
        }
        Assets.instance = this;

        this.toLoad = 0;
        this.loaded = 0;
        this.loadEndFunction = null;
        this.onProgress = null;
        this.onCriticalAssetsLoaded = null;

        this.gltfLoader = new GLTFLoader();
        this.textureLoader = new THREE.TextureLoader();
        this.exrLoader = new EXRLoader();


        this.texturePriorities = {
            'critical': [
                {
                    name: 'background', path: './textures/hdri.webp', type: 'textureLoader', options: {
                        mapping: THREE.EquirectangularReflectionMapping,
                        colorSpace: THREE.SRGBColorSpace,
                        minFilter: THREE.LinearMipmapLinearFilter,
                        magFilter: THREE.LinearFilter,
                        generateMipmaps: true
                    }
                },
                {
                    name: 'color', path: './textures/color.jpg', type: 'textureLoader', options: {
                        flipY: false,
                        colorSpace: THREE.SRGBColorSpace
                    }
                },
                {
                    name: 'clouds', path: './textures/clouds.jpg', type: 'textureLoader', options: {
                        wrapS: THREE.RepeatWrapping,
                        wrapT: THREE.RepeatWrapping,
                        colorSpace: THREE.SRGBColorSpace
                    }
                },
                {
                    name: 'cloudShadow', path: './textures/cloudshadow.jpg', type: 'textureLoader', options: {
                        wrapS: THREE.RepeatWrapping,
                        wrapT: THREE.RepeatWrapping,
                        colorSpace: THREE.SRGBColorSpace
                    }
                },
                {
                    name: 'hdri', path: './textures/courtyard.exr', type: 'exrLoader', options: {
                        mapping: THREE.EquirectangularReflectionMapping,
                        colorSpace: THREE.SRGBColorSpace
                    }
                }
            ],
            'secondary': [
                {
                    name: 'water', path: './textures/water.jpg', type: 'textureLoader', options: {
                        wrapS: THREE.RepeatWrapping,
                        wrapT: THREE.RepeatWrapping
                    }
                },
                {
                    name: 'watermap', path: './textures/watermap.jpg', type: 'textureLoader', options: {
                        wrapS: THREE.RepeatWrapping,
                        wrapT: THREE.RepeatWrapping
                    }
                }
            ],
            'additional': [
                {
                    name: 'blueprint', path: './textures/blueprint.jpg', type: 'textureLoader', options: {
                        flipY: false,
                        colorSpace: THREE.SRGBColorSpace
                    }
                },

            ],
            'specialized': []
        };

        this.textures = {
            color: null,
            water: null,
            watermap: null,
            clouds: null,
            blueprint: null,
            cloudShadow: null,
            background: null,
            hdri: null,
            subPoints: null,
        };

        // Add missing properties
        this.furnitures = {
            train1: null,
            train2: null,
            tree1: null,
            tree2: null,
            bush: null,
        };

        this.rotations = {
            1: [0.18, 0.08],
            2: [0.16, -0.13],
            3: [0.21, 0.1],
            4: [0.17, -0.13],
            5: [0.17, -0.29],
            6: [0.19, -0.3],
            7: [0.16, -0.11],
            8: [0.16, -0.58],
            9: [0.18, -0.06],
            10: [0.18, -0.06],
            11: [0.18, -0.06],
            12: [0.18, -0.06],
        };

        // Add default values for critical asset tracking
        this.criticalAssetsLoaded = false;
        this.priorityAssets = ['map', 'furnitures', 'animated'];
        this.textureLoadPriorities = ['critical', 'secondary', 'additional', 'specialized'];

        // Start loading processes
        this.loadTexturesByPriority();
        this.loadGLB();
    }

    loadTexturesByPriority() {
        // Iterate through priority levels
        this.textureLoadPriorities.forEach(priorityLevel => {
            const priorityTextures = this.texturePriorities[priorityLevel];

            if (!priorityTextures) {
                console.warn(`No textures found for priority level: ${priorityLevel}`);
                return;
            }

            priorityTextures.forEach(textureConfig => {
                this.toLoad += 1;

                // Choose the appropriate loader based on the texture type
                const loader = textureConfig.type === 'exrLoader'
                    ? this.exrLoader
                    : this.textureLoader;

                loader.load(
                    textureConfig.path,
                    (texture) => {
                        // Apply custom options
                        Object.entries(textureConfig.options || {}).forEach(([key, value]) => {
                            texture[key] = value;
                        });

                        // Store the texture
                        this.textures[textureConfig.name] = texture;

                        this.newLoad();
                    },
                    // Optional progress callback
                    undefined,
                    // Error callback
                    (error) => {
                        console.error(`Error loading texture ${textureConfig.name}:`, error);
                        this.newLoad(); // Ensure loading continues even if a texture fails
                    }
                );
            });
        });
    }
    prepareShadowProperties() {
        // Set receiveShadow on map elements
        if (this.map) {
            this.map.receiveShadow = true;
            this.map.castShadow = true;
        }

        if (this.water) {
            this.water.receiveShadow = true;
            this.water.castShadow = true;
        }

        if (this.wateranex) {
            this.wateranex.receiveShadow = true;
            this.wateranex.castShadow = true;
        }

        // Set shadow properties on furniture items
        Object.values(this.furnitures).forEach(furniture => {
            if (furniture) {
                furniture.receiveShadow = true;
                furniture.castShadow = true;
            }
        });
    }
    newLoad() {
        this.loaded += 1;
        this.updateProgress();

        if (this.loaded === this.toLoad) {
            if (this.loadEndFunction) {
                this.loadEndFunction();
            }

            // Ensure critical assets are checked at the end of loading
            this.checkCriticalAssetsLoaded();
        }
    }

    updateProgress() {
        const progress = Math.floor((this.loaded / this.toLoad) * 100);
        if (this.onProgress) {
            this.onProgress(progress);
        }
    }

    loadGLB() {
        // Load critical map elements first
        // this.toLoad += 1;
        // this.gltfLoader.load(
        //     "./map_old.glb",
        //     (gltf) => {
        //
        //         console.log("=== CONTENU DU FICHIER map_old.glb ===");
        //         gltf.scene.children.forEach((child) => {
        //             console.log(`Objet : ${child.name}`);
        //         });
        //         console.log("================================");
        //         try {
        //             // Critical elements (map, blueprint, blueprintOutline)
        //             this.map = gltf.scene.children.find((child) => child.name === "map");
        //             this.blueprint = gltf.scene.children.find(
        //                 (child) => child.name === "blueprint"
        //             );
        //             this.blueprintOutline = gltf.scene.children.find(
        //                 (child) => child.name === "blueprintoutline"
        //             );
        //
        //             // Other elements from the same GLB file
        //             this.water = gltf.scene.children.find((child) => child.name === "water");
        //             this.wateranex = gltf.scene.children.find(
        //                 (child) => child.name === "wateranex"
        //             );
        //         } catch (err) {
        //             console.error("Error processing map.glb:", err);
        //         }
        //
        //         this.newLoad();
        //     },
        //     // Progress callback
        //     undefined,
        //     // Error callback
        //     (error) => {
        //         console.error("Error loading map.glb:", error);
        //         this.newLoad();
        //     }
        // );

        // Load critical map elements first
        this.toLoad += 1;

        this.gltfLoader.load(
            "./map.glb",
            (gltf) => {

                // console.log("=== CONTENU DU FICHIER map.glb ===");
                // gltf.scene.children.forEach((child) => {
                //     // console.log(`Objet : ${child.name}`);
                // });
                // console.log("================================");
                try {
                    // Critical elements (map, blueprint, blueprintOutline)
                    this.map = gltf.scene.children.find((child) => child.name === "map");
                    this.blueprint = gltf.scene.children.find(
                        (child) => child.name === "blueprint"
                    );
                    this.blueprintOutline = gltf.scene.children.find(
                        (child) => child.name === "blueprintoutline"
                    );

                    // Other elements from the same GLB file
                    this.water = gltf.scene.children.find((child) => child.name === "water");
                    this.wateranex = gltf.scene.children.find(
                        (child) => child.name === "wateranex"
                    );
                } catch (err) {
                    // console.error("Error processing map.glb:", err);
                }

                this.newLoad();
            },
            // Progress callback
            undefined,
            // Error callback
            (error) => {
                console.error("Error loading map.glb:", error);
                this.newLoad();
            }
        );

        // Load furniture resources
        this.toLoad += 1;
        this.gltfLoader.load(
            "./furnitures.glb",
            (gltf) => {
                try {
                    for (let mesh of gltf.scene.children) {
                        this.furnitures[mesh.name] = mesh;
                    }
                } catch (err) {
                    console.error("Error processing furnitures.glb:", err);
                }
                this.newLoad();
            },
            undefined,
            (error) => {
                console.error("Error loading furnitures.glb:", error);
                this.newLoad();
            }
        );

        // Load Vinci logo
        this.toLoad += 1;
        this.gltfLoader.load(
            "./vinciLogo.glb",
            (gltf) => {
                try {
                    this.vincigeo = gltf.scene.children[0];
                    this.vincigeo.rotation.x = -1.570796461153735;
                    this.logoMaterial = this.vincigeo.material;
                } catch (err) {
                    console.error("Error processing vinciLogo.glb:", err);
                }
                this.newLoad();
            },
            undefined,
            (error) => {
                console.error("Error loading vinciLogo.glb:", error);
                this.newLoad();
            }
        );

        // Load animations last
        this.toLoad += 1;
        this.gltfLoader.load(
            "./animated.glb",
            (gltf) => {
                this.animations = gltf;
                this.newLoad();
            },
            undefined,
            (error) => {
                console.error("Error loading animated.glb:", error);
                this.newLoad();
            }
        );
    }

    checkCriticalAssetsLoaded() {
        const criticalAssets = [
            // Liste explicite des assets critiques
            this.textures.background,
            this.textures.color,
            this.textures.clouds,
            this.textures.cloudShadow,
            this.textures.hdri,
            this.textures.subPoints,
            this.textures.watermap,
            this.textures.water,
            this.textures.blueprint,
            this.map,
            this.blueprint,
            this.blueprintOutline,
            this.water,
            this.wateranex,
            this.furnitures.train1,
            this.furnitures.train2,
            this.furnitures.tree1,
            this.furnitures.tree2,
            this.furnitures.bush,
            this.vincigeo,
            this.animations,
            this.logoMaterial,
        ];

        const allCriticalAssetsLoaded = criticalAssets.every(asset =>
            asset !== null && asset !== undefined
        );

        if (allCriticalAssetsLoaded) {
            this.criticalAssetsLoaded = true;
            // console.log("Tous les assets critiques sont chargés");

            if (typeof this.onCriticalAssetsLoaded === 'function') {
                this.onCriticalAssetsLoaded();
            }
        }
    }

    setPriorityAssets(assetNames) {
        if (Array.isArray(assetNames)) {
            this.priorityAssets = assetNames;
        } else {
            console.error('setPriorityAssets requires an array of asset names');
        }
    }

    getName(name) {
        for (let n in this.furnitures) {
            if (name.includes(n)) return n;
        }
        return null; // Return null if no match found
    }

    // Method to set end load function
    setLoadEndFunction(func) {
        if (typeof func === 'function') {
            this.loadEndFunction = func;
        } else {
            console.error('setLoadEndFunction requires a function');
        }
    }

    // Method to set progress callback
    setProgressCallback(func) {
        if (typeof func === 'function') {
            this.onProgress = func;
        } else {
            console.error('setProgressCallback requires a function');
        }
    }

    // Getter for critical assets loaded status
    isCriticalAssetsLoaded() {
        // First, check the basic flag
        if (!this.criticalAssetsLoaded) {
            return false;
        }

        // Explicitly verify shadow-critical elements
        const shadowCriticalElements = [
            this.map,
            this.water,
            this.wateranex,
            this.furnitures.train1,
            this.furnitures.train2,
            this.furnitures.tree1,
            this.furnitures.tree2,
            this.furnitures.bush
        ];

        // Check that all shadow-critical elements exist
        return shadowCriticalElements.every(element =>
            element !== null && element !== undefined
        );
    }


    // Optional: Method to reset the entire loading process
    reset() {
        this.toLoad = 0;
        this.loaded = 0;
        this.criticalAssetsLoaded = false;
        this.loadEndFunction = null;
        this.onProgress = null;

        // Reset textures and furnitures
        Object.keys(this.textures).forEach(key => this.textures[key] = null);
        Object.keys(this.furnitures).forEach(key => this.furnitures[key] = null);

        // Restart loading
        this.loadTexturesByPriority();
        this.loadGLB();
    }

    // Static method to get singleton instance
    static getInstance() {
        if (!this.instance) {
            this.instance = new Assets();
        }
        return this.instance;
    }
}