import * as THREE from "three";

const animations = [
    ["excavator_2", "excavator_2Action"],
    ["excavator_3", "excavator_3Action"],
    ["Road_roller_2", "Road_roller_2Action.001"],
    ["worker_01", "Action.005"],
    ["worker_01001", "Action.002"],
    ["worker_01002", "Action.003"],
    ["worker_01003", "Action.004"],
    ["Bulldozer_1", "Bulldozer_1Action"],
    ["quarry_dump_truck_1003", "quarry_dump_truck_1.003Action"],
    ["quarry_dump_truck_bucket_001003", "quarry_dump_truck_bucket_001.003Action"],
    ["excavator_3001", "excavator_3.001Action"],
    ["Bulldozer_1001", "Bulldozer_1.001Action"],
    ["worker_01004", "Action.008"],
    ["tractor_1", "tractor_1Action"],
    ["tractor-back", "tractor-backAction"],
    ["worker_03", "Action.006"],
    ["rock-animated_1", "rock-animatedAction"],
    ["rock-animated_2", "rock-animated 2Action"],
    ["rock-animated_3", "rock-animated 3Action.001"],
    ["drone", "droneAction"],
    ["train1001", "train1.002Action.001"],
    ["train1002", "train1.002Action"],
    ["train2002", "train2.002Action"],
    ["train2003", "train2.003Action"],
    ["train2004", "train2.004Action"],
    ["Plane001", ""],

];

export default class Animations {
    constructor(world) {
        this.world = world;
        this.lastTime = 0;

        this.mixers = [];
        this.echoElements = [];
        this.droneElements = [];

        this.setup();
    }

    findAnim(targetObject, loadedAnimations) {
        // Store found animations for this object only (not children)
        let foundAnimation = null;

        // Find animation binding for this specific object
        for (const bind of animations) {
            if (bind[0] === targetObject) {
                for (const anim of loadedAnimations) {
                    if (anim.name === bind[1]) {
                        // Find the actual object in the scene
                        this.world.assets.animations.scene.traverse((obj) => {
                            if (obj.name === targetObject) {
                                foundAnimation = {
                                    object: obj,
                                    animation: anim
                                };
                            }
                        });
                        break;
                    }
                }
                break;
            }
        }

        return foundAnimation;
    }

    setup() {
        // Créer un tableau pour stocker les antennes trouvées
        let antennesFound = [];
        let echoMeshes = [];
        let drones = [];

        // Vérifier que les animations sont chargées
        if (!this.world.assets.animations || !this.world.assets.animations.scene) {
            console.warn("Les animations ne sont pas encore chargées");
            return;
        }

        // S'assurer que le matériau radioWaves est créé s'il n'existe pas déjà
        if (!this.world.materials.list.radioWaves) {
            this.world.materials.radioWaves();
        }

        // S'assurer que le matériau droneScan est créé s'il n'existe pas déjà
        if (!this.world.materials.list.droneWaves) {
            this.world.materials.droneWaves();
        }

        // Trouver toutes les antennes et leurs echos
        this.world.assets.animations.scene.traverse((obj) => {
            if (obj.name.toLowerCase().includes("antenne")) {
                antennesFound.push(obj);
                // console.log("Antenne trouvée:", obj.name);

                // Désactiver les ombres pour l'antenne
                if (obj instanceof THREE.Mesh) {
                    obj.castShadow = false;
                    obj.receiveShadow = false;
                }

                // Chercher les echos dans cette antenne
                obj.traverse((childObj) => {
                    if (childObj.name.toLowerCase().includes("echo")) {
                        // console.log("Echo trouvé dans", obj.name);
                        echoMeshes.push(childObj);

                        // Désactiver les ombres pour l'echo
                        if (childObj instanceof THREE.Mesh) {
                            childObj.castShadow = false;
                            childObj.receiveShadow = false;

                            // Créer un matériau RadioWaves spécifique pour cet echo
                            const radioWavesMaterial = this.createRadioWavesMaterial(childObj);
                            childObj.material = radioWavesMaterial;

                            // Ajouter l'echo à la liste des echos
                            this.echoElements.push({
                                mesh: childObj,
                                material: radioWavesMaterial
                            });

                            childObj.visible = true;
                        }
                    }
                });
            }

            // Trouver tous les drones
            if (obj.name.toLowerCase().includes("drone")) {
                // console.log("Drone trouvé:", obj.name);
                drones.push(obj);
            }
        });

        // Dans Animations.js, remplacer la section de création des scanners des drones par ceci
        for (const drone of drones) {
            // Create a cone for the drone scan effect - géométrie plus fine pour le laser
            const scanHeight = 275;
            const scanGeometry = new THREE.ConeGeometry(7.5, scanHeight, 32, 1, true);

            // Cloner le matériau pour les effets d'onde
            const scanMaterial = this.world.materials.list.droneWaves.clone();

            // Configurer les uniforms pour ce drone spécifique
            scanMaterial.uniforms = {
                uTime: {value: this.world.materials.uniforms.uTime.value},
                uColor: {value: new THREE.Color(0xff3333)},  // Couleur rouge vif pour les lignes
                uBaseColor: {value: new THREE.Color(0xff0000)}, // Couleur rouge pour l'intervalle
                uOrigin: {value: new THREE.Vector3(0, 0, 0)},
                uSpeed: {value: 0.15},             // Vitesse très lente pour bien voir les lignes
                uWaveThickness: {value: 0.008},    // Épaisseur très fine des lignes
                uConeHeight: {value: scanHeight},  // Hauteur du cône
                uConeAngle: {value: 3.0},          // Angle du cône très étroit pour effet laser
                uIntensity: {value: 0.8},          // Intensité des lignes
                uBaseOpacity: {value: 0.1}         // Opacité de 10% pour le fond rouge
            };

            // Créer le cône de scan
            const scanCone = new THREE.Mesh(scanGeometry, scanMaterial);

            // Positionner le cône sous le drone et le retourner
            scanCone.position.y = scanHeight / 2;
            scanCone.rotation.x = Math.PI;

            // Désactiver les ombres
            scanCone.castShadow = false;
            scanCone.receiveShadow = false;

            // Ajouter le cône au drone
            drone.add(scanCone);

            // Ajouter à la liste des éléments de drone
            this.droneElements.push({
                drone: drone,
                scanMesh: scanCone,
                material: scanMaterial
            });

            // console.log("Scan cone added to drone:", drone.name);
        }



        // Créer une liste pour stocker tous les objets qui doivent être animés
        const objectsToAnimate = [];

        // Parcourir la liste d'animations pour trouver tous les objets à animer
        for (const bind of animations) {
            if (bind[0] && bind[1]) {  // Vérifier que le nom d'objet et le nom d'animation sont valides
                objectsToAnimate.push(bind[0]);
            }
        }

        // Animer chaque objet individuellement
        for (const objName of objectsToAnimate) {
            const animData = this.findAnim(objName, this.world.assets.animations.animations);

            if (animData) {
                const animationMixer = new THREE.AnimationMixer(animData.object);
                const anim = animationMixer.clipAction(animData.animation);
                anim.play();
                this.mixers.push(animationMixer);
                // console.log(`Playing animation ${animData.animation.name} on object ${animData.object.name}`);
            }
        }

        // Appliquer les matériaux pour tous les objets
        this.world.assets.animations.scene.traverse((mesh) => {
            if (mesh instanceof THREE.Mesh) {
                if (mesh.name.toLowerCase().includes("logo")) {
                    mesh.material = this.world.assets.logoMaterial;
                } else if (!this.isPartOfAntenna(mesh, antennesFound) &&
                    !mesh.name.toLowerCase().includes("echo") &&
                    !this.isPartOfDrone(mesh, drones)) {
                    mesh.material = this.world.materials.list.color;
                }

                mesh.receiveShadow = false;
                mesh.castShadow = false;
            }
        });

        // Ajouter la scène au monde
        this.world.scene.add(this.world.assets.animations.scene);
    }

    // Fonction pour créer un matériau RadioWaves spécifique pour un echo
    createRadioWavesMaterial(echoMesh) {
        // Récupérer la position mondiale de l'echo
        const worldPosition = new THREE.Vector3();
        echoMesh.getWorldPosition(worldPosition);

        // Créer un décalage temporel aléatoire unique pour chaque antenne
        // basé sur sa position pour assurer un décalage consistent

        // Générer un délai d'initialisation aléatoire entre 0 et 5 secondes
        // Utiliser la position pour que ce soit toujours le même pour une antenne donnée
        const initDelay = 2.0 + Math.abs(Math.sin(worldPosition.x * 0.5 + worldPosition.z * 0.7) * 5.0);

        // Créer un matériau basé sur le matériau radioWaves existant
        const material = new THREE.ShaderMaterial({
            transparent: true,
            depthWrite: false,
            side: THREE.DoubleSide,
            uniforms: {
                uTime: {value: this.world.materials.uniforms.uTime.value},
                uColor: {value: new THREE.Color(0xff3333)},  // Red color for waves
                uOrigin: {value: worldPosition.clone()},     // Origin position for this echo
                uSpeed: {value: 2.0},
                uFrequency: {value: 5.0},
                uWaveSpacing: {value: 0.5},
                uIntensity: {value: 5.0},
                uFadeRate: {value: 0.2},
                uMaxDistance: {value: 0.33},
                uFadeStart: {value: 0.15},
                uCycleLength: {value: 8.0},
                uActivePeriod: {value: 2.0},
                uStartOffset: { value: initDelay }  // Random offset for initialization

            },
            vertexShader: this.world.materials.list.radioWaves.vertexShader,
            fragmentShader: this.world.materials.list.radioWaves.fragmentShader
        });

        return material;
    }

    // Vérifier si un mesh fait partie d'une des antennes
    isPartOfAntenna(mesh, antennesList) {
        for (const antenne of antennesList) {
            if (this.isDescendant(mesh, antenne)) {
                return true;
            }
        }
        return false;
    }

    isPartOfDrone(mesh, dronesList) {
        for (const drone of dronesList) {
            if (this.isDescendant(mesh, drone)) {
                return true;
            }
        }
    }

    // Fonction helper pour vérifier si un objet est descendant d'un autre
    isDescendant(child, parent) {
        let node = child;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parent;
        }
        return false;
    }

    update() {
        const newTime = performance.now() * 0.001;
        const delta = newTime - this.lastTime;
        this.lastTime = newTime;

        // Mettre à jour les animations
        for (const mixer of this.mixers) {
            mixer.update(delta);
        }

        // Mettre à jour tous les echos avec leur matériau radioWaves
        for (const echo of this.echoElements) {
            if (echo.mesh && echo.material && echo.material.uniforms) {
                // Obtenir la position mondiale de l'echo
                const worldPosition = new THREE.Vector3();
                echo.mesh.getWorldPosition(worldPosition);
                echo.mesh.castShadow = false;
                echo.mesh.receiveShadow = false;

                // Mettre à jour l'origine des ondes dans le shader
                echo.material.uniforms.uOrigin.value.copy(worldPosition);

                // Mettre à jour le temps pour l'animation des ondes
                echo.material.uniforms.uTime.value = newTime;

                const initDelay = echo.material.uniforms.uStartOffset.value;

                // Vérifier si nous avons dépassé le délai d'initialisation
                const adjustedTime = Math.max(0, newTime - initDelay);

                // Seulement appliquer les variations d'intensité si le délai est passé
                if (adjustedTime > 0) {
                    // Calculer le temps de cycle avec le décalage
                    const effectiveTime = adjustedTime * echo.material.uniforms.uSpeed.value + initDelay;
                    const cycleTime = effectiveTime % echo.material.uniforms.uCycleLength.value;
                    const isInActivePeriod = cycleTime < echo.material.uniforms.uActivePeriod.value;

                    if (isInActivePeriod) {
                        // Pendant la période active, on peut légèrement moduler l'intensité
                        const pulseIntensity = 1.0 + Math.sin(newTime * 2.0) * 0.1; // ±10% variation
                        echo.material.uniforms.uIntensity.value = 5.0 * pulseIntensity;
                    }
                }
            }
        }

        // Mettre à jour les drones avec animation d'onde continue
        for (const droneElement of this.droneElements) {
            if (droneElement.drone && droneElement.scanMesh && droneElement.material) {
                // Mettre à jour le temps pour l'animation
                droneElement.material.uniforms.uTime.value = newTime;

                // Assurer que le cône est visible
                droneElement.scanMesh.visible = true;
            }
        }
    }
}
