// import furnituresArray from "./furnitureArray.js";
// import GUI from "lil-gui";
// import {interest} from "@/Data.js";

import Swiper from "swiper";
import {Navigation} from "swiper/modules";

export default class Sliders {
	constructor(world) {
		this.world = world;
		this.containers = document.querySelectorAll(".swiper-container");
		this.sliders = [];

		if (!this.containers) {
			return;
		}

		this.buildSliders();
	}
	updatePopinHeader() {
		// const slides = container.querySelectorAll('.swiper-slide');
		// const activeSlide = slides[activeIndex];
		// const slideTitle = activeSlide.querySelector('h2').textContent;
		// const popinHeader = container.querySelector('.popin-header h2');

		// Met à jour le titre avec le h2 de la slide active
		// popinHeader.textContent = slideTitle;

		// Met à jour le numéro avec l'index de la slide actuelle + 1
	}
	buildSliders() {
		this.containers.forEach(slider => {
			const swiper = slider.querySelector(".swiper");
			const prev = slider.querySelector(".prev");
			const next = slider.querySelector(".next");

			this.sliders.push(new Swiper(swiper, {
				loop: false,
				grabCursor: true,
				slidesPerView: 1,
				speed: 500,
				spaceBetween: "4%",
				// effect: 'fade',
				// fadeEffect: {
				//     crossFade: true
				// },
				modules: [Navigation],
				navigation: {
					prevEl: prev,
					nextEl: next,
				},
				on: {
					init: (swiper) => {
						if (swiper.slides.length <= 1 && prev && next) {
							prev.style.display = 'none';
							next.style.display = 'none';
						}
						// Met à jour le header au chargement initial
						this.updatePopinHeader(slider, swiper.realIndex);
					},
					slideChange: (swiper) => {
						// Met à jour le header à chaque changement de slide
						this.updatePopinHeader(slider, swiper.realIndex);
					}
				}
			}));
		});
	}
}
