import * as THREE from "three";
import Renderer from "./Renderer.js";
import Materials from "./Materials.js";
import Control from "./Control.js";
import furnituresArray from "./furnitureArray.js";
import GUI from "lil-gui";
import Animations from './Animations.js'
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";
import {interest} from "@/Data.js";
import Sliders from "./Sliders";
import Clouds from "@/scene/Clouds";
import ProgressiveLoader from "@/scene/ProgressiveLoader";
import {addCameraDebugUI} from "@/scene/camera-debug";
// import {PerformanceManager} from "@/scene/performanceManager";

export default class World {
    constructor(assets) {
        if (World.instance) {
            return World.instance;
        }
        World.instance = this;

        this.assets = assets;
        const urlParams = new URLSearchParams(window.location.search);
// Récupère la langue du navigateur (retourne par exemple "fr" ou "fr-FR")
        const browserLang = navigator.language || navigator.userLanguage;
// Extrait la partie principale de la langue (avant le tiret si présent)
        const primaryBrowserLang = browserLang.split('-')[0];
// Utilise le paramètre URL s'il existe, sinon la langue du navigateur, sinon "en" par défaut
        this.lang = urlParams.get('lang') || primaryBrowserLang || "en";

        // this.debugON = window.location.href.includes("#dev");
        this.debugON = false;

        this.canvas = document.getElementById("canvas");
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(40, 1, 0.1, 2000);
        this.sizes;
        this.currentSprite = {value: -1};
        this.spritesMapping = {};

        this.directionalLight;
        this.materials = new Materials(this);
        this.animations = new Animations(this)
        this.clouds = new Clouds(this.scene);
        this.sliders = new Sliders(this);
        this.control = new Control(this, this.spritesMapping, this.currentSprite);
        this.renderer = new Renderer(this);
        this.qualityManager = null; // Sera initialisé par le ProgressiveLoader

        this.scene.fog = new THREE.Fog(0xe9f1fc, 20, 200);
        this.sprites = [];
        this.subPoints = [];

        this.progressiveLoader = new ProgressiveLoader(this);
        this.progressiveLoader.init();
        window.addEventListener("resize", this.resize.bind(this));
        for (const slider of this.sliders.sliders) {
            slider.on("realIndexChange", (event) => {
                if (this.currentSprite.value !== -1) {
                    this.control.setSubColor(event.realIndex);
                }
            });
        }

        this.scene.background = this.assets.textures.background;
        this.scene.environment = this.assets.textures.hdri;
        this.scene.environmentIntensity = 0.4;

        this.addLights();
        this.addWind();

        this.addModels();
        this.loadClouds();
        this.resize();

        this.initEventListeners();

        this.control.lastTime = performance.now() * 0.001;
        // this.renderer.localTick();
        //this.renderer.renderer.shadowMap.autoUpdate = false

        if (this.debugON) this.debugGui();

    }

    initEventListeners() {
        // Écouter les interactions utilisateur pour déclencher les rendus
        const interactionEvents = ['mousedown', 'mouseup', 'mousemove', 'touchstart', 'touchmove', 'touchend', 'wheel'];

        interactionEvents.forEach(eventType => {
            this.canvas.addEventListener(eventType, () => {
                if (this.renderer) {
                    this.renderer.forceRender();
                }
            });
        });

        // Surveiller les changements qui nécessitent un rendu
        window.addEventListener('resize', () => {
            if (this.renderer) {
                this.resize();
                this.renderer.forceRender();
            }
        });
    }

    addLights() {
        // Far light - basic setup
        this.directionalLight = new THREE.DirectionalLight(0xe9d4a2, 4);
        this.directionalLight.position.set(-37.3, 26.6, 3.3);

        // Defer shadow setup
        this.progressiveLoader.addToQueue(() => {
            this.configureLightShadows();
        }, this.progressiveLoader.priorityLevels.HIGH, "Configure light shadows");

        // Ambiant
        this.ambianLight = new THREE.AmbientLight(0xe9d4a2, 0.25);
        this.scene.add(this.ambianLight, this.directionalLight);

        const target = new THREE.Object3D();
        target.position.set(-0.3, -8.5, 2.44);
        this.directionalLight.target = target;
        this.scene.add(target);
    }

    configureLightShadows() {
        this.directionalLight.castShadow = true;
        this.directionalLight.shadow.radius = 4;

        this.directionalLight.shadow.camera.near = 0;
        this.directionalLight.shadow.camera.far = 150; // Restore original far value

        this.directionalLight.shadow.camera.bottom = -80;
        this.directionalLight.shadow.camera.top = 80;
        this.directionalLight.shadow.camera.left = -80;
        this.directionalLight.shadow.camera.right = 80;
        this.directionalLight.shadow.normalBias = 0.0;
        this.directionalLight.shadow.bias = -0.00412;

        const shadowMapSize = this.qualityManager ? this.qualityManager.qualityLevels[this.qualityManager.currentQualityLevel].shadowMapSize : 4000;
        this.directionalLight.shadow.mapSize.set(shadowMapSize, shadowMapSize);


        // Force shadow map update
        if (this.renderer && this.renderer.renderer) {
            this.renderer.renderer.shadowMap.enabled = true;
            this.renderer.renderer.shadowMap.needsUpdate = true;
        }

        // Force update to ensure shadow gets calculated
        if (this.directionalLight.shadow.map) {
            this.directionalLight.shadow.map.needsUpdate = true;
        }
        if (this.assets) {
            // Call the shadow preparation method
            if (typeof this.assets.prepareShadowProperties === 'function') {
                this.assets.prepareShadowProperties();
            }
        }
        if (this.renderer) {
            this.renderer.forceRender();
        }
    }

    // configureLightShadows() {
    // 	// Toujours activer les ombres indépendamment du niveau de qualité
    // 	this.directionalLight.castShadow = true;
    // 	this.directionalLight.shadow.radius = 4;
    //
    // 	this.directionalLight.shadow.camera.near = 0;
    // 	this.directionalLight.shadow.camera.far = 150;
    //
    // 	this.directionalLight.shadow.camera.bottom = -40;
    // 	this.directionalLight.shadow.camera.top = 40;
    // 	this.directionalLight.shadow.camera.left = -40;
    // 	this.directionalLight.shadow.camera.right = 40;
    // 	this.directionalLight.shadow.normalBias = 0.0252;
    // 	this.directionalLight.shadow.bias = -0.0001;
    // 	// Utiliser une taille de shadowMap adaptée à la qualité
    // 	const shadowMapSize = this.qualityManager ?
    // 		this.qualityManager.qualityLevels[this.qualityManager.currentQualityLevel].shadowMapSize :
    // 		4000;
    //
    // 	this.directionalLight.shadow.mapSize.set(shadowMapSize, shadowMapSize);
    //
    // 	// Activer explicitement les ombres au niveau du renderer
    // 	if (this.renderer && this.renderer.renderer) {
    // 		this.renderer.renderer.shadowMap.enabled = true;
    // 		this.renderer.renderer.shadowMap.needsUpdate = true;
    // 	}
    //
    // 	// Force update to ensure shadow gets calculated
    // 	if (this.directionalLight.shadow.map) {
    // 		this.directionalLight.shadow.map.needsUpdate = true;
    // 	}
    //
    // 	// Vérifier les propriétés d'ombre pour les assets
    // 	if (this.assets) {
    // 		if (typeof this.assets.prepareShadowProperties === 'function') {
    // 			this.assets.prepareShadowProperties();
    // 		}
    // 	}
    //
    // 	// Force a render to update shadows
    // 	if (this.renderer) {
    // 		this.renderer.forceRender();
    // 	}
    // }
    addWind() {
        this.progressiveLoader.addToQueue(() => {
            this.createWindEffect();
        }, this.progressiveLoader.priorityLevels.LOW, "Create wind effect");
    }


    loadClouds() {
        this.progressiveLoader.addToQueue(() => {
            this.clouds.addClouds();
        }, this.progressiveLoader.priorityLevels.HIGH, "Load clouds");
    }

    addModels() {
        // Add critical models first (blueprint and map)
        this.addCriticalModels();

        // Defer other models
        this.progressiveLoader.addToQueue(() => {
            this.addDeferredModels();
        }, this.progressiveLoader.priorityLevels.MEDIUM, "Load deferred models");

        // Defer points and sprites loading
        this.progressiveLoader.addToQueue(() => {
            this.addPoints();
        }, this.progressiveLoader.priorityLevels.MEDIUM, "Load points");

        // Defer furniture loading (lowest priority)
        this.progressiveLoader.addToQueue(() => {
            this.addFurniture();
        }, this.progressiveLoader.priorityLevels.HIGH, "Load furniture");
    }

// Critical models that should be loaded immediately
    addCriticalModels() {
        if (!this.assets.blueprint || !this.assets.blueprintOutline || !this.assets.map) {
            console.error("Assets critiques manquants. Vérifiez les noms dans le fichier GLB");

            // Essayer de charger depuis l'ancien fichier map_old.glb si nécessaire
            if (this.assets.loadFromOldMap) {
                this.assets.loadFromOldMap();
                return; // Retournez et attendez le chargement complet
            }

            // Vous pouvez créer des objets vides pour éviter les erreurs
            if (!this.assets.blueprint) this.assets.blueprint = new THREE.Object3D();
            if (!this.assets.blueprintOutline) this.assets.blueprintOutline = new THREE.Object3D();
            if (!this.assets.map) this.assets.map = new THREE.Object3D();
        }

        // Assigner les matériaux une fois que les objets existent
        this.assets.blueprint.material = this.materials.list.blueprint;
        this.assets.blueprintOutline.material = this.materials.list.blueprintOutline;
        this.assets.map.material = this.materials.list.color;

        this.scene.add(this.assets.map, this.assets.blueprint, this.assets.blueprintOutline);
    }

// Models that can be deferred
    addDeferredModels() {
        // Water elements
        this.assets.water.castShadow = true;
        this.assets.water.receiveShadow = true;
        this.assets.wateranex.castShadow = true;
        this.assets.wateranex.receiveShadow = true;
        this.assets.vincigeo.castShadow = true;
        this.assets.vincigeo.receiveShadow = true;
        this.assets.vincigeo.material.depthTest = true;

        this.scene.add(this.assets.water, this.assets.wateranex, this.assets.vincigeo);

        // Set water materials
        this.assets.water.material = this.materials.list.water;
        this.assets.wateranex.material = this.materials.list.waterNoBorder;
    }

// Load area points (can be deferred)
    addPoints() {
        // Your existing code for adding points
        for (let id in furnituresArray["area"]) {
            const area = furnituresArray["area"][id];
            const title = (this.lang === "en" ? area.titleEN : area.titleFR) ?? `POINT ${id}`;

            const sprite = new THREE.Sprite(this.materials.list.area);
            sprite.position.set(area.p[0], area.p[1], area.p[2]);
            sprite.position.y += interest.spriteYElevation;
            sprite.scale.setScalar(interest.spriteScale);
            sprite.cam = new THREE.Vector3(furnituresArray["cam"][id].p[0], furnituresArray["cam"][id].p[1], furnituresArray["cam"][id].p[2]);
            sprite.camRotation = new THREE.Vector3(furnituresArray["cam"][id].r[0], furnituresArray["cam"][id].r[1], furnituresArray["cam"][id].r[2]);
            sprite.target = new THREE.Vector3(area.p[0], area.p[1], area.p[2]);
            sprite.domId = "popin" + id;
            sprite.mappingKey = id;
            sprite.domElement = document.getElementById(sprite.domId);
            sprite.geometry.attributes.uv.needsUpdate = true;
            this.sprites.push(sprite);
            this.scene.add(sprite);

            document.body.insertAdjacentHTML("beforeend", `<div class="area-tooltip" data-area-id="${id}">
						<div class="area-tooltip-inner">${title}</div>
					  </div>`);

            if (!this.spritesMapping[sprite.mappingKey]) {
                this.spritesMapping[sprite.mappingKey] = {
                    mainSprite: sprite, childs: [], tooltip: null,
                };
            }
            // Assign the text sprite
            const tooltip = document.querySelector(`.area-tooltip[data-area-id="${id}"]`);
            this.spritesMapping[sprite.mappingKey].tooltip = tooltip; // Associate text sprite with the main sprite

            // subpoints
            if (area.subPoints) {
                area.subPoints.forEach((subPoint, index) => {
                    const activated = {value: 0};
                    const subSprite = new THREE.Sprite(this.materials.subArea(sprite.mappingKey, this.currentSprite, activated));
                    subSprite.uniformActivated = activated;
                    subSprite.index = index;
                    subSprite.position.set(subPoint.p[0], subPoint.p[1], subPoint.p[2]);
                    subSprite.position.y += interest.spriteYElevation;
                    subSprite.slider = this.sliders.sliders[id - 1];
                    subSprite.scale.setScalar(interest.spriteScale * 0.75);
                    subSprite.geometry.attributes.uv.needsUpdate = true;
                    subSprite.visible = false;
                    // Text
                    // if (subPoint.title) {
                    //   console.log(subPoint.title);

                    //   canvas.width = ctx.measureText(subPoint.title).width + 10;
                    //   ctx.reset();
                    //   //ctx.clearRect(0, 0, canvas.width, canvas.height);

                    //   ctx.fillStyle = "white";
                    //   ctx.strokeStyle = "white";
                    //   ctx.beginPath();
                    //   ctx.roundRect(0, 0, canvas.width, canvas.height, [10]);
                    //   ctx.stroke();

                    //   ctx.fillStyle = "blue";
                    //   ctx.fillText(subPoint.title, 5, canvas.height - 2);
                    //   //ctx.fillRect(0, 0, 25, 25);

                    //   const map = new THREE.CanvasTexture(canvas);
                    //   map.needsUpdate = true;
                    //   map.wrapS = THREE.RepeatWrapping;
                    //   map.wrapT = THREE.RepeatWrapping;
                    //   const text = new THREE.Mesh(
                    //     new THREE.PlaneGeometry(1, 1),
                    //     new THREE.MeshBasicMaterial({ map: map })
                    //   );
                    //   text.position.set(5, 0, 0);
                    //   text.scale.setScalar(30)
                    //   subSprite.add(text);
                    // }

                    this.subPoints.push(subSprite);
                    this.scene.add(subSprite);

                    // Mapping childs
                    this.spritesMapping[sprite.mappingKey].childs.push(subSprite);
                });
            }
        }
    }

    addFurniture() {
        // Vérifier si les ombres sont activées selon le niveau de qualité
        const shadowsEnabled = this.qualityManager ? this.qualityManager.qualityLevels[this.qualityManager.currentQualityLevel].shadows : true;

        const dummy = new THREE.Object3D();
        for (let item in furnituresArray["furnitures"]) {
            const mesh = new THREE.InstancedMesh(this.assets.furnitures[item].geometry, item.includes("tree") ? this.materials.list.trees : this.materials.list.color, furnituresArray["furnitures"][item].length);

            // Appliquer les paramètres d'ombres selon le niveau de qualité
            mesh.castShadow = shadowsEnabled;
            mesh.receiveShadow = shadowsEnabled;

            if (item.includes("tree") && shadowsEnabled) mesh.customDepthMaterial = this.materials.list.depthtrees;

            for (let id in furnituresArray["furnitures"][item]) {
                const obj = furnituresArray["furnitures"][item][id];
                dummy.position.set(obj[0], obj[1], obj[2]);
                dummy.rotation.set(obj[3], obj[4], obj[5]);
                dummy.scale.set(obj[6], obj[6], obj[6]);
                dummy.updateMatrix();
                mesh.setMatrixAt(id, dummy.matrix);
            }
            this.scene.add(mesh);
        }

        // Force renderer to update shadows if enabled
        if (this.renderer && this.renderer.renderer && shadowsEnabled) {
            this.renderer.renderer.shadowMap.needsUpdate = true;
        }
    }


    // Modification de la méthode debugGui dans World.js pour ajouter les sélecteurs de qualité

    debugGui() {
        const gui = new GUI();
        gui.open(false);
        this.gui = gui;

        const color = {
            ambient: this.ambianLight.color.getHex(),
            sun: this.directionalLight.color.getHex(),
            uBpColor: this.materials.uniforms.uBpColor.value.getHex(),
        };

        // Ajout d'un dossier pour la qualité graphique
        if (this.qualityManager) {
            const qualityFolder = gui.addFolder("Quality Settings").open(false);

            // Créer un objet pour stocker les paramètres de qualité
            const qualitySettings = {
                currentQuality: this.qualityManager.currentQualityLevel,
                autoQuality: this.qualityManager.userQualityPreference === 'auto'
            };

            // Ajouter un sélecteur de qualité
            const qualityOptions = {};
            this.qualityManager.getAvailableQualityLevels().forEach(level => {
                qualityOptions[level.name] = level.id;
            });

            // Ajouter le sélecteur déroulant
            qualityFolder.add(qualitySettings, 'currentQuality', qualityOptions)
                .name('Quality Level')
                .onChange(value => {
                    // Appliquer le niveau de qualité sélectionné
                    this.qualityManager.setQualityLevel(value);
                    // Mettre à jour le bouton d'auto-qualité
                    qualitySettings.autoQuality = false;
                });

            // Ajouter un bouton pour basculer la qualité automatique
            qualityFolder.add(qualitySettings, 'autoQuality')
                .name('Auto-Adjust Quality')
                .onChange(value => {
                    if (value) {
                        this.qualityManager.setAutoQuality();
                    } else {
                        // Si on désactive l'auto, on reste sur le niveau actuel
                        this.qualityManager.setQualityLevel(this.qualityManager.currentQualityLevel);
                    }
                });

            // Ajouter des boutons de raccourcis pour les niveaux de qualité spécifiques
            const qualityButtons = qualityFolder.addFolder('Quick Select').open(false);

            // Bouton pour le niveau Low
            qualityButtons.add({
                setLowQuality: () => {
                    this.qualityManager.setQualityLevel('low');
                    qualitySettings.currentQuality = 'low';
                    qualitySettings.autoQuality = false;
                }
            }, 'setLowQuality').name('Set Low Quality');

            // Bouton pour le niveau Medium
            qualityButtons.add({
                setMediumQuality: () => {
                    this.qualityManager.setQualityLevel('medium');
                    qualitySettings.currentQuality = 'medium';
                    qualitySettings.autoQuality = false;
                }
            }, 'setMediumQuality').name('Set Medium Quality');

            // Bouton pour le niveau High
            qualityButtons.add({
                setHighQuality: () => {
                    this.qualityManager.setQualityLevel('high');
                    qualitySettings.currentQuality = 'high';
                    qualitySettings.autoQuality = false;
                }
            }, 'setHighQuality').name('Set High Quality');

            // Bouton pour forcer la mise à jour des ombres
            qualityFolder.add({
                forceUpdateShadows: () => {
                    if (this.qualityManager.forceFullShadowUpdate) {
                        this.qualityManager.forceFullShadowUpdate();
                    } else {
                        // Alternative si la méthode n'existe pas
                        this.configureLightShadows();
                        if (this.renderer) {
                            this.renderer.forceRender();
                        }
                    }
                }
            }, 'forceUpdateShadows').name('Force Update Shadows');

            // Afficher des informations sur la qualité actuelle
            const qualityInfo = qualityFolder.addFolder('Current Settings').open(false);

            // Ajouter les principaux paramètres de qualité pour visualisation
            const currentLevel = this.qualityManager.qualityLevels[this.qualityManager.currentQualityLevel];

            // Shadows
            qualityInfo.add(currentLevel, 'shadows').name('Shadows Enabled').listen().disable();
            qualityInfo.add(currentLevel, 'shadowMapSize').name('Shadow Map Size').listen().disable();

            // Performance
            qualityInfo.add(currentLevel, 'renderScale').name('Render Scale').listen().disable();
            qualityInfo.add(currentLevel, 'pixelRatio').name('Pixel Ratio').listen().disable();

            // Effets visuels
            qualityInfo.add(currentLevel, 'fogEnabled').name('Fog Enabled').listen().disable();
            qualityInfo.add(currentLevel, 'cloudEnabled').name('Clouds Enabled').listen().disable();

            // FPS monitoring
            const performanceInfo = {
                currentFPS: () => this.qualityManager.getAverageFPS().toFixed(1),
                updateFPSDisplay: () => {
                    performanceInfo.fpsDisplay = performanceInfo.currentFPS();
                    return performanceInfo.fpsDisplay;
                },
                fpsDisplay: "0.0"
            };

            // const fpsController = qualityInfo.add(performanceInfo, 'fpsDisplay').name('Avg FPS').listen();

            // Mettre à jour l'affichage du FPS toutes les secondes
            setInterval(() => {
                performanceInfo.updateFPSDisplay();
            }, 1000);
        }

        // Shadow
        const shadow = gui.addFolder("shadow").open(false);
        shadow
            .add(this.directionalLight.shadow, "normalBias", -0.1, 0.1)
            .name("normalBias");
        shadow.add(this.directionalLight.shadow, "bias", -0.02, 0.02).name("bias");

        // Light
        const light = gui.addFolder("light").open(false);
        light
            .addColor(color, "ambient", 0, 5)
            .name("Ambient Color")
            .onChange(() => {
                this.ambianLight.color = new THREE.Color(color["ambient"]);
            });
        light.add(this.ambianLight, "intensity", 0, 10).name("Ambient intensity");
        light
            .addColor(color, "sun", 0, 5)
            .name("Sun Color")
            .onChange(() => {
                this.directionalLight.color = new THREE.Color(color["sun"]);
            });
        light.add(this.directionalLight, "intensity", 0, 5).name("Sun intensity");
        light.add(this.directionalLight.position, "x", -50, 50).name("Sun X");
        light.add(this.directionalLight.position, "y", -50, 50).name("Sun Y");
        light.add(this.directionalLight.position, "z", -50, 50).name("Sun Z");
        light
            .add(this.scene, "environmentIntensity")
            .min(0)
            .max(2)
            .step(0.001)
            .name("Env map Intensity");

        // ToneMapping
        const renderer = gui.addFolder("renderer").open(false);
        renderer.add(this.renderer.renderer, "toneMapping", {
            No: THREE.NoToneMapping,
            Linear: THREE.LinearToneMapping,
            Reinhard: THREE.ReinhardToneMapping,
            Cineon: THREE.CineonToneMapping,
            ACESFilmic: THREE.ACESFilmicToneMapping,
            AgXToneMapping: THREE.AgXToneMapping,
            NeutralToneMapping: THREE.NeutralToneMapping,
        });
        renderer
            .add(this.renderer.renderer, "toneMappingExposure")
            .min(0)
            .max(10)
            .step(0.001);

        const navigation = gui.addFolder("navigation & camera").open(false);
        navigation
            .add(this.camera, "fov", 0, 180)
            .name("FOV")
            .onChange(() => {
                this.camera.updateProjectionMatrix();
            });

        navigation.add(this.control.cameraTemp, "x", -150, 150).name("X");
        navigation.add(this.control.cameraTemp, "z", -150, 150).name("Z");
        navigation.add(this.control.cameraTemp, "y", -150, 150).name("Y");

        // Shader
        const shader = gui.addFolder("Shader").open(false);
        shader.add(this.materials.uniforms.uTest, "value", 0, 1, 0.0001);
        shader
            .addColor(color, "uBpColor")
            .name("BluePrint")
            .onChange(() => {
                this.materials.uniforms.uBpColor.value = new THREE.Color(color["uBpColor"]);
            });

        //replacer le logo vinçi qui remplace l'image
        const logo = gui.addFolder("Logo").open(false);
        logo.add(this.assets.vincigeo, "visible").name("Visible");
        logo.add(this.assets.vincigeo.position, "x", -50, 50).name("X");
        logo.add(this.assets.vincigeo.position, "y", -50, 50).name("Y");
        logo.add(this.assets.vincigeo.position, "z", -50, 50).name("Z");
        logo.add(this.assets.vincigeo.rotation, "x", -Math.PI, Math.PI).name("RotationX");
        logo.add(this.assets.vincigeo.rotation, "y", -Math.PI, Math.PI).name("RotationY");
        logo.add(this.assets.vincigeo.rotation, "z", -Math.PI, Math.PI).name("RotationZ");

        // points
        const points = gui.addFolder("Points").open(false);
        this.sprites.forEach((sprite) => {
            points
                .add(sprite.position, "x", -50, 50, 0.1)
                .name("Point " + sprite.id + " x");
            points
                .add(sprite.position, "y", -50, 50, 0.1)
                .name("Point " + sprite.id + " y");
            points
                .add(sprite.position, "z", -50, 50, 0.1)
                .name("Point " + sprite.id + " z");
        });

        // clouds
        const clouds = gui.addFolder("Clouds").open(false);
        // position of clouds
        clouds.add(this.clouds.settings, "cloudPositionX", -100, 100).name("X");
        clouds.add(this.clouds.settings, "cloudPositionY", -100, 100).name("Y");
        clouds.add(this.clouds.settings, "cloudPositionZ", -100, 100).name("Z");

        addCameraDebugUI(this, gui);
    }

    resize() {
        this.sizes = {
            width: window.innerWidth, height: window.innerHeight,
        };
        this.camera.aspect = this.sizes.width / this.sizes.height;
        this.camera.updateProjectionMatrix();

        // Resize renderer
        this.renderer.renderer.setSize(this.sizes.width, this.sizes.height);

        // Forcer un rendu après redimensionnement
        if (this.renderer.forceRender) {
            this.renderer.forceRender();
        }
    }

    createWindEffect() {
        const wind = new THREE.Mesh();
        const pointCount = 12;
        const length = 10;
        const array = [];
        const spread = 100;

        const attributes = new Float32Array(length * pointCount);
        for (let i = 0; i < length; i++) {
            const dice = 0.025 + Math.random() * 0.1;
            const geo = new THREE.PlaneGeometry(dice * 100, dice);
            array.push(geo);

            const slice = spread * (i / length) - spread * 0.5;
            const posX = slice;
            const posY = Math.random() * 20;
            const posZ = (Math.random() - 0.5) * 75;

            const localId = i * pointCount;
            for (let y = 0; y < pointCount / 3; y++) {
                const id = localId + y * 3;
                attributes[id] = posX;
                attributes[id + 1] = posY;
                attributes[id + 2] = posZ;
            }
        }

        wind.geometry = BufferGeometryUtils.mergeGeometries(array);
        wind.geometry.setAttribute("aPos", new THREE.BufferAttribute(attributes, 3));
        wind.material = this.materials.list.wind;
        this.scene.add(wind);

    }
}
