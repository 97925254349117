// Clouds.js

import * as THREE from "three";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils";

const cloudShader = {
    vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
    fragmentShader: `
    uniform sampler2D map;
    uniform vec3 fogColor;
    uniform float fogNear;
    uniform float fogFar;
    varying vec2 vUv;

    void main() {
      float depth = gl_FragCoord.z / gl_FragCoord.w;
      float fogFactor = smoothstep(fogNear, fogFar, depth);

      gl_FragColor = texture2D(map, vUv);
      gl_FragColor.w *= pow(gl_FragCoord.z, 20.0);
      gl_FragColor = mix(gl_FragColor, vec4(fogColor, gl_FragColor.w), fogFactor);
    }
  `
};

class Clouds {
    constructor(scene) {
        this.scene = scene;

        // Default settings for GUI
        this.settings = {
            fogColor: '#4584b4',
            fogNear: 10,
            fogFar: 2000,
            cloudSpeed: 0,
            cloudCount: 10,
            cloudScaleMin: 5,
            cloudScaleMax: 6,
            cloudPositionX: 0,
            cloudPositionY: 75,
            cloudPositionZ: -50,
        };

        this.cloudLayers = []; // Store cloud layers for easy access
    }


    addClouds() {
        const tLoader = new THREE.TextureLoader();

        // Load the cloud texture
        tLoader.load('https://mrdoob.com/lab/javascript/webgl/clouds/cloud10.png', (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace;
            this.createCloudLayer(texture);
        });
    }


    createCloudLayer(texture) {
        // Setup fog for the clouds
        this.scene.fog = new THREE.Fog(this.settings.fogColor, this.settings.fogNear, this.settings.fogFar);

        // Cloud material using the shader
        this.cloudMaterial = new THREE.ShaderMaterial({
            uniforms: {
                map: { value: texture },
                fogColor: { value: this.scene.fog.color },
                fogNear: { value: this.scene.fog.near },
                fogFar: { value: this.scene.fog.far },
            },
            vertexShader: cloudShader.vertexShader,
            fragmentShader: cloudShader.fragmentShader,
            depthWrite: false,
            depthTest: true,
            transparent: true,
        });

        this.regenerateClouds(this.settings.cloudCount);
    }

    regenerateClouds(count) {
        // Clear existing cloud layers
        this.cloudLayers.forEach(layer => this.scene.remove(layer));
        this.cloudLayers = []; // Reset array

        const planeGeo = new THREE.PlaneGeometry(64, 64);
        const cloudGeometries = [];

        // Create cloud planes and position them in the scene
        for (let i = 0; i < count; i++) {
            const planeObj = new THREE.Object3D();
            planeObj.position.x = Math.random() * 20 - 10;
            planeObj.position.y = 50; // range : 50 - 105
            planeObj.position.z = 75; // Spread them out along the z-axis
            planeObj.rotation.z = Math.random() * Math.PI;

            // make sure clouds face upwards
            planeObj.rotation.x = -Math.PI / 5;

            const scale = Math.random() * (this.settings.cloudScaleMax - this.settings.cloudScaleMin) + this.settings.cloudScaleMin;
            planeObj.scale.set(scale, scale, 1);
            planeObj.updateMatrix();

            const cloudPlane = planeGeo.clone();
            cloudPlane.applyMatrix4(planeObj.matrix);

            cloudGeometries.push(cloudPlane);
        }

        // Merge all cloud geometries into a single buffer geometry
        const mergedGeometry = BufferGeometryUtils.mergeGeometries(cloudGeometries);
        const cloudMesh = new THREE.Mesh(mergedGeometry, this.cloudMaterial);

        this.scene.add(cloudMesh);
        this.cloudLayers.push(cloudMesh); // Store cloud layer

        // Animate cloud movement
        this.animateClouds(this.cloudLayers);
        this.updateCloudPositions(); // Set initial positions
    }

    animateClouds(cloudLayers) {
        const startTime = Date.now();

        const animate = () => {
            const elapsed = Date.now() - startTime;
            const speed = this.settings.cloudSpeed; // Use speed from settings

            // Move each cloud layer based on time
            cloudLayers.forEach((layer, index) => {
                layer.position.x = (elapsed * speed) % 8000 + index * -8000;
            });

            requestAnimationFrame(animate);
        };

        animate();
    }

    updateCloudPositions() {
        this.cloudLayers.forEach(layer => {
            layer.position.x = this.settings.cloudPositionX;
            layer.position.y = this.settings.cloudPositionY;
            layer.position.z = this.settings.cloudPositionZ;
        });
    }

    // GUI Update Methods
    updateFogColor(value) {
        this.scene.fog.color.set(value);
        this.cloudMaterial.uniforms.fogColor.value.set(value); // Update shader uniform
    }

    updateFogNear(value) {
        this.scene.fog.near = value;
        this.cloudMaterial.uniforms.fogNear.value = value; // Update shader uniform
    }

    updateFogFar(value) {
        this.scene.fog.far = value;
        this.cloudMaterial.uniforms.fogFar.value = value; // Update shader uniform
    }

    updateCloudSpeed(value) {
        this.settings.cloudSpeed = value;
    }
}

export default Clouds;
