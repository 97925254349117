// Modifications pour Renderer.js
import * as THREE from "three";
import Stats from "stats.js";

export default class Renderer {
  constructor(world) {
    this.world = world;
    this.lastFrameTime = 0;
    this.frameId = null;
    this.isRendering = false;
    this.targetFPS = 30;
    this.frameInterval = 1000 / this.targetFPS;
    this.needsRender = true;
    this.inactivityTimer = 0;
    this.lastInteractionTime = performance.now();
    this.frustum = new THREE.Frustum();
    this.projScreenMatrix = new THREE.Matrix4();
    this.isLowPowerMode = false;

    // Create renderer with optimized settings
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.world.canvas,
      antialias: true,      // Désactiver l'antialiasing pour améliorer les performances
      alpha: true,          // Désactiver le canal alpha
      precision: 'highp', // Utiliser une précision moyenne
      powerPreference: 'high-performance',
      stencil: true,        // Désactiver le stencil buffer
      depth: true,           // Garder le depth buffer
      preserveDrawingBuffer: true,
      failIfMajorPerformanceCaveat: true
    });

    // Set essential properties
    this.renderer.colorSpace = THREE.SRGBColorSpace;
    this.renderer.autoClear = false;

    // Optimized texture handling
    this.renderer.maxMorphTargets = 0; // Désactiver les morphTargets si non utilisés
    this.renderer.maxMorphNormals = 0;

    // Defer advanced settings
    if (world.progressiveLoader) {
      world.progressiveLoader.addToQueue(() => {
      }, world.progressiveLoader.priorityLevels.HIGH, "Setup advanced renderer");
    }

    // Debug stats
    if (this.world.debugON) {
      this.stats = new Stats();
      document.body.appendChild(this.stats.dom);
    }

    this.localTick = this.tick.bind(this);

    // Optimiser les rendus en fonction de l'activité utilisateur
    window.addEventListener('resize', () => {
      this.needsRender = true;
      this.lastInteractionTime = performance.now();
    });

    // Ajouter des écouteurs pour détecter l'activité utilisateur
    const interactionEvents = ['mousedown', 'mouseup', 'mousemove', 'touchstart', 'touchmove', 'touchend', 'wheel'];
    interactionEvents.forEach(event => {
      window.addEventListener(event, () => {
        this.lastInteractionTime = performance.now();
        this.isLowPowerMode = false;
        this.needsRender = true;
      });
    });

    this.startRenderLoop();
  }

  startRenderLoop() {
    if (!this.isRendering) {
      this.isRendering = true;
      this.lastFrameTime = performance.now();
      this.frameId = requestAnimationFrame(this.localTick);
    }
  }

  stopRenderLoop() {
    if (this.isRendering && this.frameId) {
      cancelAnimationFrame(this.frameId);
      this.frameId = null;
      this.isRendering = false;
    }
  }


  // Optimized tick function with inactivity detection and frustum culling
  tick(timestamp) {
    const deltaTime = timestamp - this.lastFrameTime;

    // Ne render que si nécessaire ou si l'intervalle de frame est atteint
    if (this.needsRender || deltaTime >= this.frameInterval) {
      if (this.world.debugON) this.stats.begin();

      this.lastFrameTime = timestamp - (deltaTime % this.frameInterval);

      // Mise à jour du temps pour les matériaux
      this.world.materials.uniforms.uTime.value = timestamp * 0.001;

      // Mise à jour de l'opacité des points
      this.world.materials.uniforms.uSpriteFade.value +=
          ((this.world.currentSprite.value === -1 ? 0 : 1) -
              this.world.materials.uniforms.uSpriteFade.value) *
          0.2;

      // Mise à jour des contrôles
      this.world.control.update();

      // Mettre à jour le frustum pour le culling
      this.updateFrustum();

      // Mise à jour des animations si elles existent et sont visibles
      if (this.world.animations) {
        this.world.animations.update();
      }

      // Effacer manuellement le buffer si autoClear est désactivé
      this.renderer.clear();

      // Faire le rendu
      this.renderer.render(this.world.scene, this.world.camera);

      // Réinitialiser le flag
      this.needsRender = false;

      if (this.world.debugON) this.stats.end();
    }

    // Compter cette frame pour le QualityManager
    if (this.world.qualityManager) {
      this.world.qualityManager.frameCount++;
    }

    // Continuer la boucle si le rendu est actif
    if (this.isRendering) {
      this.frameId = requestAnimationFrame(this.localTick);
    }
  }
  // Nouvelle méthode pour mettre à jour le frustum et faire le culling des objets
  updateFrustum() {
    this.projScreenMatrix.multiplyMatrices(
        this.world.camera.projectionMatrix,
        this.world.camera.matrixWorldInverse
    );
    this.frustum.setFromProjectionMatrix(this.projScreenMatrix);

    // Appliquer le frustum culling aux objets de la scène
    this.cullObjects(this.world.scene);
  }

  // Culling des objets hors du champ de vision
  cullObjects(object) {
    if (!object.visible) return;

    // Ignorer certains types d'objets
    if (object.isLight || object.isCamera) return;

    // Vérifier si l'objet a une bounding box
    if (object.geometry && object.geometry.boundingSphere) {
      const sphere = object.geometry.boundingSphere.clone();
      sphere.applyMatrix4(object.matrixWorld);

      // Désactiver les objets hors du frustum, sauf pour les objets avec des ombres
      const isInFrustum = this.frustum.intersectsSphere(sphere);

      // Conserver les objets proches visibles 
      const distanceToCamera = this.world.camera.position.distanceTo(object.position);
      const isClose = distanceToCamera < 50; // Garder les objets proches toujours visibles

      // Ne pas désactiver complètement les objets qui projettent des ombres
      if (object.castShadow) {
        // Garder les ombres mais réduire la qualité pour les objets lointains
        if (!isInFrustum && !isClose && object.material) {
          object.material.shadowSide = THREE.BackSide; // Ombre simplifiée
        } else {
          object.material.shadowSide = THREE.FrontSide; // Ombre normale
        }
      }
    }

    // Récursion sur les enfants
    if (object.children) {
      for (let i = 0; i < object.children.length; i++) {
        this.cullObjects(object.children[i]);
      }
    }
  }

  forceRender() {
    this.needsRender = true;
    this.lastInteractionTime = performance.now();
  }
}