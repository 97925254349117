// camera-debug.js
// GUI de débug pour les positions et rotations de caméras

export function addCameraDebugUI(world, gui) {
  if (!world.debugON) {
    return;
  }
  // Vérifier si les sprites sont chargés
  if (!world.sprites || world.sprites.length === 0 || Object.keys(world.spritesMapping).length === 0) {
    console.warn("Debug GUI: Les points de caméra ne sont pas encore chargés.");

    // Ajouter un bouton pour recharger le GUI une fois les sprites chargés
    gui.add({
      rechargeCameraGUI: () => {
        // console.log("Rechargement du GUI des caméras...");
        // Vérifier que les sprites sont chargés avant de réessayer
        if (world.sprites && world.sprites.length > 0) {
          addCameraDebugUI(world, gui);
          return true;
        } else {
          // console.warn("Les sprites ne sont toujours pas chargés.");
          return false;
        }
      }
    }, 'rechargeCameraGUI').name('🔄 Recharger GUI Caméras');

    return;
  }

  // Création d'un dossier principal pour les caméras
  const cameraFolder = gui.addFolder("Camera Debug");
  cameraFolder.open(false);

  // Récupération des données de caméras depuis furnituresArray
  const camerasData = world.spritesMapping;

  // Fonction pour mettre à jour les données dans furnituresArray
  const updateCameraData = (id, posOrRot, axis, value) => {
    // Mise à jour de la valeur dans l'objet sprite
    if (posOrRot === 'position') {
      camerasData[id].mainSprite.cam[axis] = value;
    } else if (posOrRot === 'rotation') {
      camerasData[id].mainSprite.camRotation[axis] = value;
    }

    // Force un rendu pour voir les changements
    if (world.renderer) {
      world.renderer.forceRender();
    }
  };

  // Création d'un sous-dossier pour chaque caméra
  for (const id in camerasData) {
    const sprite = camerasData[id].mainSprite;
    if (!sprite || !sprite.cam || !sprite.camRotation) continue;

    const cameraPointFolder = cameraFolder.addFolder(`Point ${id}`);

    // Position de la caméra
    const posFolder = cameraPointFolder.addFolder("Position");

    // Créer un objet de contrôle pour la position
    // THREE.Vector3 n'expose pas x,y,z comme des propriétés directement modifiables via GUI
    const positionControl = {
      x: sprite.cam.x,
      y: sprite.cam.y,
      z: sprite.cam.z
    };

    posFolder.add(positionControl, 'x', -100, 100, 0.01)
        .name("X")
        .onChange((value) => {
          sprite.cam.x = value;
          updateCameraData(id, 'position', 'x', value);
        });

    posFolder.add(positionControl, 'y', -100, 100, 0.01)
        .name("Y")
        .onChange((value) => {
          sprite.cam.y = value;
          updateCameraData(id, 'position', 'y', value);
        });

    posFolder.add(positionControl, 'z', -100, 100, 0.01)
        .name("Z")
        .onChange((value) => {
          sprite.cam.z = value;
          updateCameraData(id, 'position', 'z', value);
        });

    // Rotation de la caméra (en degrés pour plus de lisibilité)
    const rotFolder = cameraPointFolder.addFolder("Rotation (degrés)");

    // On convertit les angles en degrés pour l'interface
    const rotationDegrees = {
      x: sprite.camRotation.x,
      y: sprite.camRotation.y,
      z: sprite.camRotation.z
    };

    rotFolder.add(rotationDegrees, 'x', -180, 180, 0.01)
        .name("X")
        .onChange((value) => {
          rotationDegrees.x = value;
          sprite.camRotation.x = value;
          updateCameraData(id, 'rotation', 'x', value);
        });

    rotFolder.add(rotationDegrees, 'y', -180, 180, 0.01)
        .name("Y")
        .onChange((value) => {
          rotationDegrees.y = value;
          sprite.camRotation.y = value;
          updateCameraData(id, 'rotation', 'y', value);
        });

    rotFolder.add(rotationDegrees, 'z', -180, 180, 0.01)
        .name("Z")
        .onChange((value) => {
          rotationDegrees.z = value;
          sprite.camRotation.z = value;
          updateCameraData(id, 'rotation', 'z', value);
        });

    // Bouton pour exporter la configuration de caméra actuelle
    cameraPointFolder.add({
      exportConfig: () => {

        // Copier dans le presse-papier
        const textToCopy = `"${id}": {"p": [${sprite.cam.x.toFixed(3)}, ${sprite.cam.y.toFixed(3)}, ${sprite.cam.z.toFixed(3)}], "r": [${sprite.camRotation.x.toFixed(2)}, ${sprite.camRotation.y.toFixed(2)}, ${sprite.camRotation.z.toFixed(2)}]},`;
        navigator.clipboard.writeText(textToCopy)
            .then(() => console.log('Configuration copiée dans le presse-papier'))
            .catch(err => console.error('Erreur lors de la copie:', err));
      }
    }, 'exportConfig').name('Export Config');

    // Option pour visualiser la vue de cette caméra
    cameraPointFolder.add({
      viewFromCamera: () => {
        // Si un autre point est déjà sélectionné, on l'annule
        if (world.currentSprite.value !== -1) {
          world.spritesMapping[world.currentSprite.value].mainSprite.domElement.classList.remove("show");
        }

        // Activer ce point
        world.currentSprite.value = id;
        sprite.domElement.classList.add("show");

        // Forcer le rendu
        if (world.renderer) {
          world.renderer.forceRender();
        }
      }
    }, 'viewFromCamera').name('Vue caméra');
  }

  // Bouton pour exporter toutes les configurations
  cameraFolder.add({
    exportAllConfigs: () => {
      let output = "\"cam\": {\n";

      for (const id in camerasData) {
        const sprite = camerasData[id].mainSprite;
        if (!sprite || !sprite.cam || !sprite.camRotation) continue;

        output += `  "${id}": {"p": [${sprite.cam.x.toFixed(3)}, ${sprite.cam.y.toFixed(3)}, ${sprite.cam.z.toFixed(3)}], "r": [${sprite.camRotation.x.toFixed(2)}, ${sprite.camRotation.y.toFixed(2)}, ${sprite.camRotation.z.toFixed(2)}]},\n`;
      }

      output += "},";

      console.log("Configuration de toutes les caméras:");
      console.log(output);

      // Copier dans le presse-papier
      navigator.clipboard.writeText(output)
          .then(() => console.log('Toutes les configurations copiées dans le presse-papier'))
          .catch(err => console.error('Erreur lors de la copie:', err));
    }
  }, 'exportAllConfigs').name('Export All Configs');

  return cameraFolder;
}