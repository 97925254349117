// Modifications à apporter à ProgressiveLoader.js pour intégrer la gestion de qualité

export default class ProgressiveLoader {
  constructor(world) {
    this.world = world;
    this.taskQueue = [];
    this.isProcessing = false;

    this.priorityLevels = {
      CRITICAL: 0,  // Highest priority
      HIGH: 1,
      MEDIUM: 2, LOW: 3        // Lowest priority
    };
  }

  init() {
    // Ajouter la tâche d'initialisation du gestionnaire de qualité en priorité HIGH
    this.addToQueue(
        this.initializeQualityManager.bind(this),
        this.priorityLevels.HIGH,
        "Initialize and apply quality settings"
    );

    // Ajouter la tâche de configuration des ombres après les réglages de qualité
    this.addToQueue(
        this.configureShadows.bind(this),
        this.priorityLevels.CRITICAL,
        "Configure shadow rendering"
    );

    return this; // For chaining
  }

  // Nouvelle méthode pour initialiser et appliquer les réglages de qualité
  initializeQualityManager() {
    // console.log("Initializing quality manager...");

    // Vérifier si le QualityManager existe déjà
    if (!this.world.qualityManager) {
      // Si le QualityManager n'est pas encore créé, l'importer et l'initialiser
      const { QualityManager } = require("./QualityManager");
      this.world.qualityManager = new QualityManager(this.world);
    }

    // Initialiser le gestionnaire de qualité
    if (this.world.qualityManager) {
      this.world.qualityManager.initialize();
      // console.log(`Quality level set to: ${this.world.qualityManager.currentQualityLevel}`);
    }
  }

  addToQueue(task, priority, description = "") {
    this.taskQueue.push({
      task,
      priority: priority || this.priorityLevels.MEDIUM,
      description
    });

    // Sort the queue by priority
    this.taskQueue.sort((a, b) => a.priority - b.priority);

    // Start processing if not already started
    if (!this.isProcessing) {
      this.processNextTask();
    }
  }

  processNextTask() {
    if (this.taskQueue.length === 0) {
      this.isProcessing = false;
      this.onComplete();
      return;
    }

    this.isProcessing = true;
    const nextTask = this.taskQueue.shift();

    // console.log(`Processing task: ${nextTask.description}`);

    try {
      nextTask.task();
    } catch (error) {
      console.error(`Error executing task ${nextTask.description}:`, error);
    }

    // Use setTimeout to avoid blocking the main thread
    setTimeout(() => this.processNextTask(), 0);
  }

  onComplete() {
    // console.log("All progressive loading tasks completed");

    // Forcer la mise à jour des ombres en fonction des réglages de qualité
    if (this.world.qualityManager) {

      // Si en qualité low, s'assurer que les ombres sont désactivées
      if (this.world.qualityManager.currentQualityLevel === 'low') {
        // console.log("Applying low quality shadow settings (disabled)");
        if (this.world.renderer && this.world.renderer.renderer) {
          this.world.renderer.renderer.shadowMap.enabled = true;
        }
      }
    }

    // Final shadow update to ensure everything is rendered properly
    if (this.world.renderer && this.world.renderer.renderer) {
      this.world.renderer.renderer.shadowMap.needsUpdate = true;
      this.world.renderer.forceRender();
    }
  }

  // Méthode configureShadows modifiée pour respecter les paramètres de qualité
  configureShadows() {
    // Récupérer les paramètres de qualité
    const qualitySettings = this.world.qualityManager ?
        this.world.qualityManager.qualityLevels[this.world.qualityManager.currentQualityLevel] :
        { shadows: true, shadowMapSize: 4000, shadowDistance: 150 };

    // Configure directional light shadows with settings based on quality
    if (this.world.directionalLight) {
      // Respecter le paramètre shadows du niveau de qualité
      this.world.directionalLight.castShadow = qualitySettings.shadows;

      if (qualitySettings.shadows) {
        this.world.directionalLight.shadow.radius = 4;
        this.world.directionalLight.shadow.camera.near = 0;
        this.world.directionalLight.shadow.camera.far = qualitySettings.shadowDistance || 150;
        this.world.directionalLight.shadow.camera.bottom = -40;
        this.world.directionalLight.shadow.camera.top = 40;
        this.world.directionalLight.shadow.camera.left = -40;
        this.world.directionalLight.shadow.camera.right = 40;
        this.world.directionalLight.shadow.normalBias = 0.0;
        this.world.directionalLight.shadow.bias = -0.00412;

        // Utiliser la taille de la shadowMap du niveau de qualité ou la valeur par défaut
        const shadowMapSize = qualitySettings.shadowMapSize || 4000;
        this.world.directionalLight.shadow.mapSize.set(shadowMapSize, shadowMapSize);
      }
    }

    // Configure renderer shadow settings based on quality
    if (this.world.renderer && this.world.renderer.renderer) {
      // Enable shadows based on quality settings
      this.world.renderer.renderer.shadowMap.enabled = qualitySettings.shadows;

      // Force update of shadow maps if shadows are enabled
      if (qualitySettings.shadows) {
        this.world.renderer.renderer.shadowMap.needsUpdate = true;
      }
    }

    // Verify shadow settings on critical objects
    if (this.world.assets) {
      // Call the shadow preparation method
      if (typeof this.world.assets.prepareShadowProperties === 'function') {
        this.world.assets.prepareShadowProperties();
      }
    }

    // console.log(`Shadow configuration applied with shadows ${qualitySettings.shadows ? 'enabled' : 'disabled'}`);
  }
}