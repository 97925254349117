import {control, interest} from "@/Data";
import * as THREE from "three";

export default class Control {
    constructor(world, spritesMapping, currentSprite) {
        this.world = world;
        this.spritesMapping = spritesMapping;
        this.currentSprite = currentSprite;

        this.data = control;

        if (window.innerWidth > 500) {
            this.minPanMove = new THREE.Vector3(
                control.cameraMinXMove,
                -1000,
                control.cameraMinZMove
            );
            this.maxPanMove = new THREE.Vector3(
                control.cameraMaxXMove,
                1000,
                control.cameraMaxZMove
            );
        } else {
            this.minPanMove = new THREE.Vector3(
                control.cameraMinXMobileMove,
                -1000,
                control.cameraMinZMobileMove
            );
            this.maxPanMove = new THREE.Vector3(
                control.cameraMaxXMobileMove,
                1000,
                control.cameraMaxZMobileMove
            );
        }

        this.raycaster = new THREE.Raycaster();
        this.drag = new THREE.Vector2();
        this.mouse = new THREE.Vector2();
        this.controlHold = false;

        this.target = new THREE.Vector3(-6, 3, 19);
        this.targetOrigin = new THREE.Vector3(-12, 6, 19);
        this.targetTemp = new THREE.Vector3(-12, 6, 19);
        this.cameraTemp = new THREE.Vector3();
        this.initialCam = new THREE.Vector3(-51.6, 70, 87.3);
        // this.initialCam = new THREE.Vector3(-60, 90, 110);
        this.camOffset = new THREE.Vector3(-28, 51, 51);

        this.lastMouse = new THREE.Vector2();
        this.lastTime = 0;

        this.cameraTemp.copy(this.targetTemp).add(this.initialCam);
        this.world.camera.lookAt(this.target);



        this.minPanTarget = new THREE.Vector3();
        this.maxPanTarget = new THREE.Vector3();
        this.minPanTarget.z = this.targetOrigin.z - (this.camOffset.z - this.minPanMove.z);
        this.maxPanTarget.z = this.targetOrigin.z + (this.maxPanMove.z - this.camOffset.z);
        this.minPanTarget.x = this.targetOrigin.x - (this.camOffset.x - this.minPanMove.x);
        this.maxPanTarget.x = this.targetOrigin.x + (this.maxPanMove.x - this.camOffset.x);
        this.spriteHover = null;

        this.initPopinCloseHandler();

        // Events
        if ("ontouchstart" in document.documentElement) {
            window.addEventListener("touchmove", this.touchmove.bind(this));
            this.world.canvas.addEventListener("touchstart", this.start.bind(this));
            document.addEventListener("touchend", this.end.bind(this));
        } else {
            window.addEventListener("mousemove", this.mousemove.bind(this));
            this.world.canvas.addEventListener("mousedown", this.start.bind(this));
            document.addEventListener("mouseup", this.end.bind(this));
        }
    }

    initPopinCloseHandler() {
        const closeButtons = document.querySelectorAll(".popin-close");

        // Parcourt chaque bouton et ajoute un gestionnaire d'événements 'click'
        closeButtons.forEach((button) => {
            button.addEventListener("mousedown", () => {
                // Trouve la popin parente la plus proche de ce bouton
                const popin = button.closest(".popin");
                // Si la popin a la classe 'open', on la retire pour fermer la popin
                if (popin && popin.classList.contains("show")) {
                    popin.classList.remove("show");
                    if (this.currentSprite.value !== -1) {
                        this.spritesMapping[
                            this.currentSprite.value
                            ].mainSprite.domElement.classList.remove("show");
                        this.currentSprite.value = -1;
                    }
                }
            });
            // touch event
            button.addEventListener("touchstart", () => {
                // Trouve la popin parente la plus proche de ce bouton
                const popin = button.closest(".popin");
                // Si la popin a la classe 'open', on la retire pour fermer la popin
                if (popin && popin.classList.contains("show")) {
                    popin.classList.remove("show");
                    if (this.currentSprite.value !== -1) {
                        this.spritesMapping[
                            this.currentSprite.value
                            ].mainSprite.domElement.classList.remove("show");
                        this.currentSprite.value = -1;
                    }
                }
            });
        });
    }
    setSubColor(id) {
        for (let i = 0; i < this.spritesMapping[this.currentSprite.value].childs.length; i++) {
            this.spritesMapping[this.currentSprite.value].childs[i].visible = true;
            if (!this.spritesMapping[this.currentSprite.value].childs[i].uniformColor) {
                this.spritesMapping[this.currentSprite.value].childs[i].uniformColor = {
                    value: new THREE.Color(),
                };
            }
            if (i === id) {
                this.spritesMapping[this.currentSprite.value].childs[i].uniformActivated.value = 1;
            } else {
                this.spritesMapping[this.currentSprite.value].childs[i].uniformActivated.value = 0;
            }
        }
    }

    start(event) {
        this.controlHold = true;

        // Touch screen
        if (event.changedTouches) {
            event.preventDefault();
            this.mouse.x = (event.changedTouches[0].clientX / this.world.sizes.width) * 2 - 1;
            this.mouse.y = -(event.changedTouches[0].clientY / this.world.sizes.height) * 2 + 1;
        }

        this.lastMouse.copy(this.mouse);

        this.raySprites();
        if (this.spriteHover) {
            const tooltip = this.getTooltipForSprite(this.spriteHover);
            if (tooltip) {
                tooltip.classList.remove("active");
            }

            if (this.spriteHover.domElement) {
                // TODO désactivation temporaire des popins
                this.spriteHover.domElement.classList.add("show");
                this.currentSprite.value = this.spriteHover.mappingKey;
                this.setSubColor(0);
            } else {
                this.setSubColor(this.spriteHover.index);

                this.spriteHover.slider.slideTo(this.spriteHover.index);
            }

        } else {
            // If a sprite is active, clear all sprites and their children
            if (this.currentSprite.value !== -1) {
                // Clear main sprite
                this.spritesMapping[this.currentSprite.value].mainSprite.domElement.classList.remove("show");

                // Reset all subpoints color and visibility
                for (const key in this.spritesMapping) {
                    const mapping = this.spritesMapping[key];
                    if (mapping.childs) {
                        mapping.childs.forEach(child => {
                            // Reset color
                            if (child.uniformActivated) {
                                child.uniformActivated.value = 0;
                            }
                            // Reset visibility
                            child.visible = false;
                        });
                    }
                }

                this.currentSprite.value = -1;
            }
        }
    }

    end() {
        this.controlHold = false;
    }

    touchmove(event) {
        event.preventDefault();
        this.mouse.x = (event.changedTouches[0].clientX / this.world.sizes.width) * 2 - 1;
        this.mouse.y = -(event.changedTouches[0].clientY / this.world.sizes.height) * 2 + 1;
        this.move(event);
    }

    mousemove(event) {
        event.stopImmediatePropagation();
        if (this.world.sizes) {
            this.mouse.x = (event.clientX / this.world.sizes.width) * 2 - 1;
            this.mouse.y = -(event.clientY / this.world.sizes.height) * 2 + 1;
            this.move(event);
        }
    }

    move() {
        if (this.controlHold) this.dragPan();
        this.raySprites();
    }

    raySprites() {
        // Raycast sprites of childs of an area
        const map =
            this.currentSprite.value === -1
                ? this.world.sprites
                : this.spritesMapping[this.currentSprite.value].childs;
        const validMap = map.filter((sprite) => sprite && sprite.isObject3D);

        this.raycaster.setFromCamera(this.mouse, this.world.camera);

        // Fire the ray
        const intersects = this.raycaster.intersectObjects(validMap);
        if (intersects.length > 0) {
            this.spriteHover = intersects[0].object;
            document.body.style.cursor = "pointer";

            // Show the corresponding text sprite
            const tooltip = this.getTooltipForSprite(this.spriteHover);
            if (tooltip) {
                if (!tooltip.isDisplaying) {
                    tooltip.isDisplaying = true;
                    tooltip.classList.add("active");
                }
            }

            // Scale the hovered sprite
            if (this.spriteHover) {
                this.spriteHover.scale.setScalar(interest.spriteScale * 1.1);
            }
        } else if (this.spriteHover) {
            // Reset the scale of the previously hovered sprite
            this.spriteHover.scale.setScalar(interest.spriteScale);

            // Hide the text sprite if spriteHover is not null
            const tooltip = this.getTooltipForSprite(this.spriteHover);
            if (tooltip) {
                if (tooltip.isDisplaying) {
                    tooltip.isDisplaying = false;
                    tooltip.classList.remove("active");
                }
            }

            this.spriteHover = null; // Reset spriteHover
            document.body.style.cursor = "default";
        }
    }

    getTooltipForSprite(sprite) {
        return this.spritesMapping[sprite?.mappingKey]?.tooltip || null;
    }

    dragPan() {
        const elapsed = this.world.materials.uniforms.uTime.value - this.lastTime;

        this.drag.set(
            this.mouse.x - this.lastMouse.x,
            this.mouse.y - this.lastMouse.y
        );
        this.drag.rotateAround(new THREE.Vector2(), this.data.defaultRotY);
        const x = this.drag.x * this.data.panSpeed;
        const y = this.drag.y * this.data.panSpeed;
        this.targetTemp.x -= x;
        this.targetTemp.z += y;
        this.cameraTemp.x -= x;
        this.cameraTemp.z += y;
        //
        this.lastMouse.copy(this.mouse);
        this.clampTarget();
        this.clampMove();


        this.target.lerp(this.targetTemp, (this.data.dragLerp * elapsed));
        this.world.camera.position.lerp(this.cameraTemp, (this.data.dragLerp * elapsed));
    }

    clampTarget() {
        this.targetTemp.clamp(this.minPanTarget, this.maxPanTarget);
    }

    clampMove() {
        //console.log(this.minPanMove, this.maxPanMove);
        this.cameraTemp.clamp(this.minPanMove, this.maxPanMove);
    }

    update() {
        const elapsed = this.world.materials.uniforms.uTime.value - this.lastTime;
        const swapLerp = this.data.swapLerp * elapsed;
        const dragLerp = this.data.dragLerp * elapsed;
        this.lastTime = this.world.materials.uniforms.uTime.value;

        const tooltip = this.getTooltipForSprite(this.spriteHover);
        if (tooltip) {
            const screenPosition = this.spriteHover.position.clone();
            screenPosition.project(this.world.camera);
            const translateX = screenPosition.x * this.world.sizes.width * 0.5 + 24;
            const translateY = -screenPosition.y * this.world.sizes.height * 0.5 - 21;
            tooltip.style.transform = `translate(${translateX}px, ${translateY}px)`;
        }

        // Is zoomed
        if (this.currentSprite.value !== -1) {
            const point = this.spritesMapping[this.currentSprite.value].mainSprite;
            // console.log(point);
            const newPointCam = point.cam.clone();
            const newPointTarget = point.target.clone();
            newPointCam.x += point.cam.x * (this.mouse.x * this.data.localPointPanRange);
            newPointCam.y += point.cam.y * (this.mouse.y * this.data.localPointPanRange);
            newPointTarget.x += point.target.x * (this.mouse.x * this.data.localPointPanRange);
            newPointTarget.y += point.target.y * (this.mouse.y * this.data.localPointPanRange);
            this.target.lerp(point.target, swapLerp);
            this.world.camera.position.lerp(newPointCam, swapLerp);
        } else {
            const pannedCamera = this.cameraTemp.clone();
            const pannedTarget = this.targetTemp.clone();

            // Pan
            if (!this.controlHold && this.currentSprite.value === -1) {
                pannedCamera.x += this.mouse.x * this.data.localPanRange;
                pannedCamera.y += this.mouse.y * this.data.localPanRange;

                pannedTarget.x += this.mouse.x * this.data.localPanRange;
                pannedTarget.y += this.mouse.y * this.data.localPanRange;
            }

            this.world.camera.position.lerp(pannedCamera, dragLerp);
            this.target.lerp(pannedTarget, dragLerp);
        }

        this.world.camera.lookAt(this.target);
    }
}
