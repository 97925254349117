<template>
  <canvas id="canvas"></canvas>
</template>

<script>
import Assets from '../scene/Assets.js';
import World from '../scene/World.js';
// import { gsap } from 'gsap'; // Importer GSAP directement pour éviter l'import dynamique

export default {
  name: 'SceneWebgl',
  data() {
    return {
      loading: true,
      progress: 0,
      initialLoadComplete: false,
      world: null
    };
  },
  mounted() {
    const minLoadingTime = 2000;
    this.assets = new Assets(); // Create the Assets instance only once
    const startTime = Date.now();

    // Prioritize asset loading
    this.assets.setPriorityAssets(['map', 'blueprint', 'blueprintOutline']);

    this.assets.onProgress = (progress) => {
      this.progress = progress;
      this.updateLoadingState();
    };

    this.assets.onCriticalAssetsLoaded = () => {
      // Crée le monde quand les assets critiques sont chargés
      if (!this.world) {
        this.world = new World(this.assets);
        this.initialLoadComplete = true;
      }
    };

    this.assets.loadEndFunction = () => {
      const elapsed = Date.now() - startTime;
      const remainingTime = minLoadingTime - elapsed;

      if (remainingTime > 0) {
        setTimeout(() => {
          this.hideLoadingScreen();
        }, remainingTime);
      } else {
        this.hideLoadingScreen();
      }
    };
  },
  methods: {
    hideLoadingScreen() {
      if (!this.world) {
        this.world = new World(this.assets);
      }
      this.loading = false;
      this.updateLoadingState();
    },
    updateLoadingState() {
      this.$emit('update-loading', {
        loading: this.loading,
        progress: this.progress,
        initialLoadComplete: this.initialLoadComplete
      });
    },
    zoomIn() {
      if (this.world && this.world.control) {
        // Importer GSAP
        import('gsap').then(({ gsap }) => {
          // Positions cibles de la caméra
          const targetY = this.world.control.camOffset.y;
          const targetZ = this.world.control.camOffset.z;
          const targetX = this.world.control.camOffset.x;

          // Forcer le mode rendu continu pendant l'animation
          const enableContinuousRendering = () => {
            // Forcer le rendu à chaque frame d'animation
            if (this.world.renderer) {
              this.world.renderer.needsRender = true;

              // Augmenter temporairement le FPS pendant l'animation
              this.world.renderer.targetFPS = 60;
            }
          };

          // Restaurer le mode rendu à la demande après l'animation
          const restoreOptimizedRendering = () => {
            if (this.world.renderer) {
              // Remettre le FPS cible à sa valeur d'origine
              this.world.renderer.targetFPS = 30;
            }
          };

          // Configurer l'animation GSAP avec onUpdate pour forcer les rendus
          gsap.to(this.world.control.cameraTemp, {
            x: targetX,
            y: targetY,
            z: targetZ,
            duration: 2.5, // Durée de l'animation en secondes
            ease: "power2.inOut",
            onUpdate: enableContinuousRendering,
            onComplete: () => {
              // Désactiver les clouds une fois l'animation terminée
              this.removeClouds();

              // Revenir au mode de rendu optimisé
              restoreOptimizedRendering();

              // console.log("Camera zoom completed and clouds removed");
            }
          });
        });
      }
    },

    // Nouvelle méthode pour supprimer les clouds
    removeClouds() {
      if (this.world && this.world.clouds) {
        // Supprimer les cloud layers de la scène
        if (this.world.clouds.cloudLayers && this.world.clouds.cloudLayers.length > 0) {
          this.world.clouds.cloudLayers.forEach(layer => {
            if (layer && layer.parent) {
              this.world.scene.remove(layer);
            }
          });
          // Vider le tableau des cloudLayers
          this.world.clouds.cloudLayers = [];

          // Également, désactiver le fog lié aux clouds si nécessaire
          if (this.world.scene.fog) {
            // Ajuster la distance du fog pour le rendre imperceptible
            this.world.scene.fog.near = 1000;
            this.world.scene.fog.far = 1001;
          }
        }
      }
    }
  },
};
</script>

<style scoped>
</style>