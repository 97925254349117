<template>
  <div class="introduction" :class="{ 'slide-up': isSlidingUp}">
    <div class="introduction-wrapper" :class="{'fade-in': isFadingIn }">
      <div class="tag">
        <p class="tag-content">VINCI Construction <span class="bold">GeoInfrastructure</span></p>
      </div>
      <h1 class="title" v-if="lang ==='fr'">Spécialiste dans le terrassement des grands projets d’infrastructure</h1>
      <h1 class="title" v-if="lang ==='en'">Specialist in earthworks for major infrastructure projects</h1>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
const urlParams = new URLSearchParams(window.location.search);
// Récupère la langue du navigateur (retourne par exemple "fr" ou "fr-FR")
const browserLang = navigator.language || navigator.userLanguage;
// Extrait la partie principale de la langue (avant le tiret si présent)
const primaryBrowserLang = browserLang.split('-')[0];
// Utilise le paramètre URL s'il existe, sinon la langue du navigateur, sinon "en" par défaut
const lang = urlParams.get('lang') || primaryBrowserLang || "en";
export default {
  name: "SceneIntroduction",
  data() {
    return {
      isSlidingUp: false,
      isFadingIn: false,
      lang
    };
  },
  mounted() {
    // if (window.location.href.includes("#dev")) {
    //   document.body.classList.add("intro-finished");
    //   this.$emit("end-introduction");
    //   gsap.to(".background", {
    //     opacity: 0,
    //   });
    // }
    // else {
      setTimeout(() => {
        this.isSlidingUp = true;
        this.isFadingIn = true;
      }, 0);

      const titleElement = document.querySelector(".title");
      const textContent = titleElement.textContent.trim();

      const words = textContent.split(' ');
      titleElement.innerHTML = '';

      words.forEach(word => {
        const wordSpan = document.createElement('span');
        wordSpan.classList.add('word');

        word.split('').forEach(char => {
          const charSpan = document.createElement('span');
          charSpan.classList.add('char');
          charSpan.textContent = char;
          wordSpan.appendChild(charSpan);
        });

        const space = document.createElement('span');
        space.textContent = '\u00A0';
        titleElement.appendChild(wordSpan);
        titleElement.appendChild(space);
      });

      const charSpans = document.querySelectorAll('.title .char');


      const tag = document.querySelector('.tag');

      const timeline = gsap.timeline();
      timeline.from(tag, {
        opacity: 0,
        y: -25,
        duration: 1,
        ease: "power2.out",
      });
      timeline.delay(1)
      timeline.from(charSpans, {
        opacity: 0,
        y: -10,
        stagger: 0.025,
        duration: 0.4,
        ease: "bezier(0.5, 0, 0.5, 1)",
      }, "-=0.5");
      gsap.to(".background", {
        opacity: 0,
        delay: 5,
        onComplete: () => {
          document.body.classList.add("intro-finished");
          this.$emit("end-introduction");
        }
      });
    // }

  }
};
</script>

<style lang="scss">
.introduction {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Vinci Sans Expanded", sans-serif;
  transform: translateY(0);
  transition: transform 1s ease 4.5s;


  .title {
    margin: auto;
    max-width: 66%;
    text-align: center;
    font-size: 3.75rem;
    font-weight: bold;
    color: $color-primary;
    white-space: pre-line;
  }

  .word {
    display: inline-block;
    margin-right: 0.2em;

  }

  .char {
    display: inline-block;
  }
}

.introduction-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-content: center;
  justify-items: center;
  margin: auto;
  width: fit-content;
  grid-row-gap: 1rem;
  padding-bottom: 2.75rem;
  opacity: 0;
  transition: opacity 0.1s ease;
}

.fade-in {
  opacity: 1;
}

.slide-up {
  transform: translateY(-100vh);
}

.tag {
  width: fit-content;
  max-width: 100%;
  color: $color-secondary;
  border: 1px solid rgba($color-secondary, 0.2);
  border-radius: 5.5rem;
  padding: 14px 22px;
}

.tag-content {
  margin: 0;
  font-size: 0.875em;
  font-weight: 300;
  text-transform: uppercase;

  .bold {
    font-weight: 500;
  }
}

@media (max-width: 500px) {
  .introduction {
    .title {
      max-width: 90%;
      font-size: 1.8rem;
    }


    .tag {
      max-width: 90%;
      border: 1px solid rgba($color-secondary, 0.2);
      padding: 7px 11px;
    }

    .tag-content {
      font-size: 0.75em;
    }
  }
}

</style>
