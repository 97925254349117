import * as THREE from "three";

export class QualityManager {
  constructor(world) {
    this.world = world;
    this.renderer = world.renderer.renderer;
    this.scene = world.scene;
    this.camera = world.camera;
    
    // Performance monitoring
    this.fpsHistory = [];
    this.fpsHistoryMaxLength = 60; // Store 1 minute of FPS data (assuming 60 fps)
    this.lastFrameTime = 0;
    this.frameCount = 0;
    this.intervalId = null;
    this.stablePerformanceTime = 0;
    this.lastAdaptationTime = 0;
    this.adaptationCooldown = 5000; // 5 seconds cooldown between adaptations


    this.options = {
      runBenchmark: true, // Exécuter un benchmark de performance au démarrage
      adaptiveQuality: true, // Ajuster la qualité automatiquement
      debugMode: false // Afficher les logs détaillés
    };
    // Quality levels available
    this.qualityLevels = {
      high: {
        name: "High",
        shadowMapSize: 2048, // Réduit de 1024 à 768
        shadowType: THREE.PCFSoftShadowMap, // Conservé
        pixelRatio: Math.min(window.devicePixelRatio, 2), // Réduit de 2 à 1.5
        maxLights: 3, // Réduit de 4 à 3
        renderScale: 0.8, // Réduit de 0.8 à 0.75
        anisotropy: 16, // Réduit de 4 à 2
        maxTextureSize: 2048, // Conservé
        particleCount: 75, // Réduit de 100 à 75
        fogEnabled: true, // Conservé
        fogDensity: 0.025, // Augmenté légèrement pour cacher les détails distants
        toneMapping: THREE.ACESFilmicToneMapping, // Conservé
        toneMappingExposure: 1.0, // Conservé
        physicallyCorrectLights: false, // Conservé (désactivé)
        maxInstanceCount: 400, // Réduit de 500 à 400
        animationLevel: 0.4, // Réduit de 0.5 à 0.4
        terrainDetail: 0.6, // Réduit de 0.5 à 0.4
        reflections: false, // Conservé (désactivé)
        cloudDetail: 0.4, // Réduit de 0.5 à 0.4
        cloudEnabled: true, // Conservé
        shadows: true, // Conservé
        shadowDistance: 100, // Réduit de 100 à 75
        bloomEnabled: false, // Conservé (désactivé)
        bloomStrength: 0.4, // Réduit de 0.6 à 0.4
        bloomRadius: 0.3, // Réduit de 0.4 à 0.3
        ssaoEnabled: false, // Conservé (désactivé)
        ssaoRadius: 8, // Réduit de 12 à 8
        ssaoIntensity: 0.4, // Réduit de 0.6 à 0.4
        ambientLightIntensity: 0.4, // Légèrement augmenté de 0.4 à 0.45 pour compenser la réduction des autres lumières
        directionalLightIntensity: 3.2, // Réduit de 3.5 à 3.2
        antialiasing: true // Conservé
      },

      // Les autres niveaux restent inchangés
      medium: {
        name: "Medium",
        shadowMapSize: 2048,
        shadowType: THREE.PCFShadowMap,
        pixelRatio: Math.min(window.devicePixelRatio, 1.5),
        maxLights: 4,
        renderScale: 0.8,
        anisotropy: 4,
        maxTextureSize: 1024,
        particleCount: 50,
        fogEnabled: true,
        fogDensity: 0.025,
        toneMapping: THREE.ACESFilmicToneMapping,
        toneMappingExposure: 1.0,
        physicallyCorrectLights: false,
        maxInstanceCount: 500,
        animationLevel: 0.6,
        terrainDetail: 0.6,
        reflections: false,
        cloudDetail: 0.4,
        cloudEnabled: true,
        shadows: true,
        shadowDistance: 100,
        bloomEnabled: false,
        bloomStrength: 0.4,
        bloomRadius: 0.3,
        ssaoEnabled: false,
        ssaoRadius: 8,
        ssaoIntensity: 0.6,
        ambientLightIntensity: 0.4,
        directionalLightIntensity: 3.5,
        antialiasing: true
      },
      low: {
        name: "Low",
        shadowMapSize: 1024,
        shadowType: THREE.BasicShadowMap,
        pixelRatio: Math.min(window.devicePixelRatio, 1.5),
        maxLights: 3,
        renderScale: 0.8,
        anisotropy: 4,
        maxTextureSize: 1024,
        particleCount: 25,
        fogEnabled: false,
        fogDensity: 0.03,
        toneMapping: THREE.ACESFilmicToneMapping,
        toneMappingExposure: 1.0,
        physicallyCorrectLights: false,
        maxInstanceCount: 250,
        animationLevel: 0.4,
        terrainDetail: 0.4,
        reflections: false,
        cloudDetail: 0.2,
        cloudEnabled: true,
        shadows: false,
        shadowDistance: 50,
        bloomEnabled: false,
        bloomStrength: 0.2,
        bloomRadius: 0.2,
        ssaoEnabled: false,
        ssaoRadius: 4,
        ssaoIntensity: 0.4,
        ambientLightIntensity: 0.5,
        directionalLightIntensity: 3.0,
        antialiasing: false
      }
    };
    
    // CPU/GPU detection results
    this.deviceCapabilities = {
      tier: 'low',              // Classification générale: 'low', 'medium', 'high'
      isLowEndDevice: false,       // Indication directe pour les appareils de faible puissance
      isMobile: this.detectMobile(),
      gpuInfo: 'Unknown',          // Informations sur le GPU
      gpuVendor: 'Unknown',        // Fabricant du GPU
      gpuTier: 'unknown',          // Classification spécifique du GPU
      cpuCores: navigator.hardwareConcurrency || 2,
      cpuTier: 'unknown',          // Classification du CPU basée sur les cœurs et performances
      memory: this.estimateAvailableMemory(),
      maxTextureSize: this.renderer.capabilities.maxTextureSize,
      maxAnisotropy: this.renderer.capabilities.getMaxAnisotropy(),
      precisionFragmentShader: this.renderer.capabilities.precision,
      supportsMSAA: false,         // Support du Multi-Sample Anti-Aliasing
      supportsHDR: this.checkHDRSupport(),
      supportsWebGPU: false,       // Support de WebGPU
      supportsWebGL2: false,       // Support de WebGL 2
      supportsFloatTextures: false,// Support des textures en virgule flottante
      supportsCompressedTextures: {// Support des textures compressées par format
        astc: false,
        etc2: false,
        s3tc: false,
        pvrtc: false,
        bptc: false
      },
      benchmarkScore: 0,           // Score du benchmark (si exécuté)
      screenResolution: {
        width: window.screen.width * (window.devicePixelRatio || 1),
        height: window.screen.height * (window.devicePixelRatio || 1),
        pixelRatio: window.devicePixelRatio || 1
      },
      recommendedSettings: {       // Paramètres recommandés basés sur la détection
        renderScale: 0.75,
        shadowQuality: 'low',
        textureQuality: 'low',
        effectsQuality: 'low',
        drawDistance: 'low',
        antiAliasing: 'TAA',
        reflections: false
      }
    };
    
    // Current quality level
    this.currentQualityLevel = null;
    
    // Target performance values
    this.targetFPS = 60;
    this.minAcceptableFPS = 30;
    this.upgradeThreshold = 55; // FPS above which we consider upgrading quality
    this.downgradeThreshold = 40; // FPS below which we consider downgrading quality

    // User preference quality level (defaults to auto)
    this.userQualityPreference = 'auto';
    
    // Initialize
    this.detectHardwareCapabilities();
  }
  
  // System initialization
  initialize() {
    // Définir le niveau de qualité initial en fonction des capacités matérielles détectées
    this.setInitialQualityLevel();

    // Obtenir le niveau de qualité courant
    const quality = this.qualityLevels[this.currentQualityLevel];
    // console.log(`Initializing with quality level: ${quality.name}, shadows: ${quality.shadows}`);

    // Appliquer les paramètres de qualité
    this.applyCurrentQualitySettings();

    // Démarrer la surveillance des performances si en mode automatique
    if (this.options.adaptiveQuality) {
      this.startPerformanceMonitoring();
    }

    // Vérification supplémentaire pour le niveau "low"
    if (this.currentQualityLevel === 'low') {
      // console.log("Low quality detected: ensuring shadows are disabled");

      // Désactiver explicitement les ombres si en qualité "low"
      if (this.renderer) {
        this.renderer.shadowMap.enabled = quality.shadows;
        this.renderer.shadowMap.needsUpdate = quality.shadows;
      }

      // Désactiver explicitement les ombres sur la lumière directionnelle si elle existe
      if (this.world.directionalLight) {
        this.world.directionalLight.castShadow = quality.shadows;
      }

      // Parcourir la scène pour désactiver les ombres sur tous les objets
      this.scene.traverse((obj) => {
        if (obj.isMesh || obj.isInstancedMesh) {
          if (obj.castShadow !== undefined) obj.castShadow = quality.shadows;
          if (obj.receiveShadow !== undefined) obj.receiveShadow = quality.shadows;
        }
        if (obj.isLight && obj !== this.world.directionalLight && obj !== this.world.ambianLight) {
          if (obj.castShadow !== undefined) obj.castShadow = quality.shadows;
        }
      });
    }

    return this; // Pour permettre le chaînage
  }
  checkHDRSupport() {
    if (window.matchMedia) {
      // Vérifier le support de l'affichage HDR via l'API MediaQuery
      const hdrSupport = window.matchMedia('(dynamic-range: high)').matches;
      return hdrSupport;
    }
    return false;
  }

  /**
   * Estime la mémoire disponible en mégaoctets
   * @returns {number} Mémoire estimée en MB
   */
  estimateAvailableMemory() {
    // Tenter d'utiliser l'API de mémoire du navigateur si disponible
    if (navigator.deviceMemory) {
      return navigator.deviceMemory * 1024; // Convertir GB en MB
    }

    // Estimation basée sur l'appareil
    if (this.detectMobile()) {
      return 2048; // Estimation conservative pour les appareils mobiles
    }

    // Estimation par défaut pour les ordinateurs de bureau
    return 4096;
  }
  // Start monitoring FPS and adjusting quality
  startPerformanceMonitoring() {
    this.lastFrameTime = performance.now();
    this.frameCount = 0;
    
    // Calculate FPS every second
    this.intervalId = setInterval(() => {
      const now = performance.now();
      const elapsedMs = now - this.lastFrameTime;
      
      if (elapsedMs > 0) {
        const currentFPS = this.frameCount / (elapsedMs / 1000);
        this.updateFPSHistory(currentFPS);
        
        // Only adjust quality automatically if user preference is 'auto'
        if (this.userQualityPreference === 'auto') {
          this.adaptQualityBasedOnPerformance();
        }
        
        this.frameCount = 0;
        this.lastFrameTime = now;
      }
    }, 1000);
    
    // Register frame counter
    const originalRenderFunction = this.world.renderer.tick;
    this.world.renderer.tick = (timestamp) => {
      this.frameCount++;
      return originalRenderFunction.call(this.world.renderer, timestamp);
    };
  }
  
  // Stop monitoring
  stopPerformanceMonitoring() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
  
  // Update FPS history
  updateFPSHistory(fps) {
    this.fpsHistory.push(fps);
    
    // Keep history at max length
    if (this.fpsHistory.length > this.fpsHistoryMaxLength) {
      this.fpsHistory.shift();
    }
    
    // Log for debugging
    // console.log(`Current FPS: ${fps.toFixed(1)}, Average: ${this.getAverageFPS().toFixed(1)}`);
  }
  
  // Calculate average FPS from history
  getAverageFPS() {
    if (this.fpsHistory.length === 0) return 0;
    
    const sum = this.fpsHistory.reduce((total, fps) => total + fps, 0);
    return sum / this.fpsHistory.length;
  }
  
  // Detect if we're on a mobile device
  detectMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  
  // Detect hardware capabilities to determine initial quality tier
  detectHardwareCapabilities() {
    // Détection de WebGPU (API graphique de nouvelle génération)
    if (navigator.gpu) {
      this.deviceCapabilities.supportsWebGPU = true;
      this.deviceCapabilities.tier = 'high';
      this.deviceCapabilities.gpuInfo = 'WebGPU supported';
      this.deviceCapabilities.gpuTier = 'high';

      // Configurations recommandées pour les appareils supportant WebGPU
      this.deviceCapabilities.recommendedSettings.renderScale = 1.0;
      this.deviceCapabilities.recommendedSettings.shadowQuality = 'high';
      this.deviceCapabilities.recommendedSettings.antiAliasing = 'MSAA';
      this.deviceCapabilities.recommendedSettings.reflections = true;
    }

    // Détection détaillée de WebGL
    let gl = null;

    // Essayer d'obtenir le contexte WebGL2 d'abord
    try {
      gl = this.renderer.getContext();
      if (!gl) {
        const canvas = document.createElement('canvas');
        gl = canvas.getContext('webgl2') || canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      }

      if (gl instanceof WebGL2RenderingContext) {
        this.deviceCapabilities.supportsWebGL2 = true;
      }
    } catch (e) {
      console.warn("Erreur lors de la détection WebGL:", e);
    }

    // Extraire les informations détaillées sur le GPU si WebGL est disponible
    if (gl) {
      // Obtenir des informations non masquées sur le rendu
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo) {
        this.deviceCapabilities.gpuInfo = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        const vendorInfo = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        this.deviceCapabilities.gpuVendor = vendorInfo;

        if (this.options.debugMode) {
          // console.log(`GPU Vendor: ${vendorInfo}`);
          // console.log(`GPU Renderer: ${this.deviceCapabilities.gpuInfo}`);
        }
      }

      // Obtenir les capacités maximales des textures
      this.deviceCapabilities.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
      this.deviceCapabilities.maxAnisotropy = this.getMaxAnisotropy(gl);

      // Vérifier le support des textures en virgule flottante
      this.deviceCapabilities.supportsFloatTextures = !!gl.getExtension('OES_texture_float');

      // Vérifier le support du MSAA
      if (this.deviceCapabilities.supportsWebGL2) {
        this.deviceCapabilities.supportsMSAA = true;
        this.deviceCapabilities.maxSamples = gl.getParameter(gl.MAX_SAMPLES);
      } else {
        this.deviceCapabilities.supportsMSAA = !!gl.getExtension('WEBGL_multisampled_render_to_texture');
      }

      // Vérifier le support des formats de texture compressés
      this.detectCompressedTextureSupport(gl);
    }

    // Analyse détaillée des GPU basée sur les informations obtenues
    if (this.deviceCapabilities.gpuInfo !== 'Unknown') {
      const gpuInfo = this.deviceCapabilities.gpuInfo;
      const gpuInfoLower = gpuInfo.toLowerCase();

      // Catégorisation des GPU mobiles
      const isMobileGPU = /adreno|mali|powervr|apple gpu|videocore|gc\d+/i.test(gpuInfo);

      // Détection des GPU mobiles de différentes générations
      if (isMobileGPU) {
        // Apple GPU (iPhone/iPad)
        if (/apple gpu/i.test(gpuInfo)) {
          // Estimation de la génération des Apple GPU
          if (/iphone 13|iphone 14|iphone 15|ipad pro/i.test(navigator.userAgent)) {
            this.deviceCapabilities.gpuTier = 'high-mobile';
          } else if (/iphone 11|iphone 12|ipad air/i.test(navigator.userAgent)) {
            this.deviceCapabilities.gpuTier = 'medium-mobile';
          } else {
            this.deviceCapabilities.gpuTier = 'low-mobile';
          }
        }
        // Qualcomm Adreno
        else if (/adreno/i.test(gpuInfo)) {
          const adreno = gpuInfo.match(/adreno\s*(\d+)/i);
          if (adreno && adreno[1]) {
            const adrenoSeries = parseInt(adreno[1]);
            if (adrenoSeries >= 700) {
              this.deviceCapabilities.gpuTier = 'high-mobile';
            } else if (adrenoSeries >= 600) {
              this.deviceCapabilities.gpuTier = 'medium-mobile';
            } else {
              this.deviceCapabilities.gpuTier = 'low-mobile';
            }
          }
        }
        // ARM Mali
        else if (/mali/i.test(gpuInfo)) {
          if (/mali-g7|mali-g9/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'high-mobile';
          } else if (/mali-g5|mali-g6/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium-mobile';
          } else if (/mali-t/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'low-mobile';
          }
        }
        // PowerVR
        else if (/powervr/i.test(gpuInfo)) {
          if (/powervr gx6/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium-mobile';
          } else {
            this.deviceCapabilities.gpuTier = 'low-mobile';
          }
        }

        // Configurations par défaut pour les appareils mobiles
        if (this.deviceCapabilities.gpuTier === 'high-mobile') {
          this.deviceCapabilities.tier = 'medium';
        } else {
          this.deviceCapabilities.tier = 'low';
          this.deviceCapabilities.isLowEndDevice = true;
        }
      }
      // Détection détaillée des GPU de bureau
      else {
        // NVIDIA GPUs
        if (/nvidia/i.test(gpuInfo)) {
          if (/rtx\s*40|rtx\s*30/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'ultra';
          } else if (/rtx\s*20|gtx\s*16/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'high';
          } else if (/gtx\s*10/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium-high';
          } else if (/gtx\s*9|gtx\s*7|gtx\s*8/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium';
          } else {
            this.deviceCapabilities.gpuTier = 'low';
          }
        }
        // AMD GPUs
        else if (/amd|radeon|ati/i.test(gpuInfo)) {
          if (/rx\s*7|rx\s*6/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'ultra';
          } else if (/rx\s*5/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'high';
          } else if (/rx\s*[4]/i.test(gpuInfoLower) || /vega/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium-high';
          } else if (/r9|r7/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium';
          } else {
            this.deviceCapabilities.gpuTier = 'low';
          }
        }
        // Intel GPUs
        else if (/intel/i.test(gpuInfo)) {
          if (/arc/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium-high';
          } else if (/iris\s*xe/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'medium';
          } else if (/uhd|iris\s*plus/i.test(gpuInfoLower)) {
            this.deviceCapabilities.gpuTier = 'low-medium';
          } else {
            this.deviceCapabilities.gpuTier = 'low';
            this.deviceCapabilities.isLowEndDevice = true;
          }
        }

        // Attribuer le niveau général en fonction du GPU
        switch (this.deviceCapabilities.gpuTier) {
          case 'ultra':
            this.deviceCapabilities.tier = 'ultra';
            break;
          case 'high':
            this.deviceCapabilities.tier = 'high';
            break;
          case 'medium-high':
          case 'medium':
            this.deviceCapabilities.tier = 'medium';
            break;
          case 'low-medium':
          case 'low':
            this.deviceCapabilities.tier = 'low';
            if (this.deviceCapabilities.gpuTier === 'low') {
              this.deviceCapabilities.isLowEndDevice = true;
            }
            break;
        }
      }
    } else {
      // Aucune information GPU disponible, faire une estimation conservative
      this.deviceCapabilities.tier = this.deviceCapabilities.isMobile ? 'low' : 'medium';
      this.deviceCapabilities.gpuTier = this.deviceCapabilities.isMobile ? 'low-mobile' : 'medium';
      if (this.deviceCapabilities.isMobile) {
        this.deviceCapabilities.isLowEndDevice = true;
      }
    }

    // Analyse des capacités CPU
    if (navigator.hardwareConcurrency) {
      const cores = navigator.hardwareConcurrency;

      // Classifier le CPU en fonction du nombre de cœurs et du type d'appareil
      if (this.deviceCapabilities.isMobile) {
        if (cores >= 8) {
          this.deviceCapabilities.cpuTier = 'high-mobile';
        } else if (cores >= 6) {
          this.deviceCapabilities.cpuTier = 'medium-mobile';
        } else {
          this.deviceCapabilities.cpuTier = 'low-mobile';
        }
      } else {
        if (cores >= 16) {
          this.deviceCapabilities.cpuTier = 'ultra';
        } else if (cores >= 8) {
          this.deviceCapabilities.cpuTier = 'high';
        } else if (cores >= 4) {
          this.deviceCapabilities.cpuTier = 'medium';
        } else {
          this.deviceCapabilities.cpuTier = 'low';
        }
      }

      // Ajuster le niveau global si le CPU est significativement plus faible que le GPU
      if (this.deviceCapabilities.cpuTier === 'low' && this.deviceCapabilities.tier === 'high') {
        this.deviceCapabilities.tier = 'medium';
      }
    }

    // Vérifications supplémentaires des limitations
    if (this.deviceCapabilities.maxTextureSize < 2048) {
      this.deviceCapabilities.tier = 'low';
      this.deviceCapabilities.isLowEndDevice = true;
      this.deviceCapabilities.recommendedSettings.textureQuality = 'low';
    }

    // Estimer la mémoire disponible et ajuster les paramètres en conséquence
    if (this.deviceCapabilities.memory < 2048) {
      this.deviceCapabilities.tier = 'low';
      this.deviceCapabilities.isLowEndDevice = true;
      this.deviceCapabilities.recommendedSettings.drawDistance = 'low';
    } else if (this.deviceCapabilities.memory > 8192) {
      this.deviceCapabilities.recommendedSettings.textureQuality = 'high';
    }


    // Journaliser toutes les informations détectées
    // console.log(`Detected device tier: ${this.deviceCapabilities.tier}`);
    // console.log(`GPU Info: ${this.deviceCapabilities.gpuInfo}`);
    // console.log(`GPU Tier: ${this.deviceCapabilities.gpuTier}`);
    // console.log(`CPU Cores: ${this.deviceCapabilities.cpuCores}`);
    // console.log(`CPU Tier: ${this.deviceCapabilities.cpuTier}`);
    // console.log(`Memory Estimate: ${this.deviceCapabilities.memory}MB`);
    // console.log(`Max Texture Size: ${this.deviceCapabilities.maxTextureSize}`);
    // console.log(`Max Anisotropy: ${this.deviceCapabilities.maxAnisotropy}`);
    // console.log(`Supports MSAA: ${this.deviceCapabilities.supportsMSAA}`);
    // console.log(`Supports HDR: ${this.deviceCapabilities.supportsHDR}`);
    // console.log(`Detected pixel ratio: ${this.deviceCapabilities.screenResolution.pixelRatio}`);
    // console.log(`Screen resolution: ${this.deviceCapabilities.screenResolution.width}x${this.deviceCapabilities.screenResolution.height}`);

    if (this.options.debugMode) {
      // console.log(`Recommended settings:`, this.deviceCapabilities.recommendedSettings);
    }
  }

  getMaxAnisotropy(gl) {
    const ext = gl.getExtension('EXT_texture_filter_anisotropic') ||
        gl.getExtension('MOZ_EXT_texture_filter_anisotropic') ||
        gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic');

    if (ext) {
      return gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
    }
    return 1;
  }

  /**
   * Détecte le support des formats de textures compressées
   * @param {WebGLRenderingContext|WebGL2RenderingContext} gl - Contexte WebGL
   */
  detectCompressedTextureSupport(gl) {
    // ASTC (Adaptatif Scalable Texture Compression) - Moderne, efficace mais support limité
    this.deviceCapabilities.supportsCompressedTextures.astc =
        !!gl.getExtension('WEBGL_compressed_texture_astc');

    // ETC2 (Ericsson Texture Compression 2) - Standard sur Android
    this.deviceCapabilities.supportsCompressedTextures.etc2 =
        !!gl.getExtension('WEBGL_compressed_texture_etc');

    // S3TC/DXT (S3 Texture Compression) - Largement supporté sur desktop
    this.deviceCapabilities.supportsCompressedTextures.s3tc =
        !!gl.getExtension('WEBGL_compressed_texture_s3tc');

    // PVRTC (PowerVR Texture Compression) - iOS
    this.deviceCapabilities.supportsCompressedTextures.pvrtc =
        !!gl.getExtension('WEBGL_compressed_texture_pvrtc');

    // BPTC/BC7 (Block Compression 7) - Haute qualité
    this.deviceCapabilities.supportsCompressedTextures.bptc =
        !!gl.getExtension('EXT_texture_compression_bptc');

    // Journaliser les formats supportés en mode debug
    if (this.options.debugMode) {
      // console.log("Formats de texture compressée supportés:");
      // console.log("- ASTC:", this.deviceCapabilities.supportsCompressedTextures.astc);
      // console.log("- ETC2:", this.deviceCapabilities.supportsCompressedTextures.etc2);
      // console.log("- S3TC/DXT:", this.deviceCapabilities.supportsCompressedTextures.s3tc);
      // console.log("- PVRTC:", this.deviceCapabilities.supportsCompressedTextures.pvrtc);
      // console.log("- BPTC/BC7:", this.deviceCapabilities.supportsCompressedTextures.bptc);
    }
  }
  // Set initial quality level based on detected capabilities
  setInitialQualityLevel() {
    // Map hardware tier to initial quality level
    let initialQuality = 'medium'; // Default
    
    // switch (this.deviceCapabilities.tier) {
    //   case 'high':
    //     initialQuality = 'high';
    //     break;
    //   case 'medium':
    //     initialQuality = 'medium';
    //     break;
    //   case 'low':
    //     initialQuality = 'low';
    //     break;
    //   default:
    //     initialQuality = 'low';
    // }
    
    // Mobile devices start one tier lower
    if (this.deviceCapabilities.isMobile) {
      if (initialQuality === 'high') initialQuality = 'medium';
      else if (initialQuality === 'medium') initialQuality = 'low';
      else if (initialQuality === 'low') initialQuality = 'low';
    }
    
    // Apply initial quality
    this.currentQualityLevel = initialQuality;
    // console.log(`Setting initial quality to: ${this.qualityLevels[initialQuality].name}`);
  }


  adaptQualityBasedOnPerformance() {
    const now = performance.now();
    const avgFPS = this.getAverageFPS();

    // Ne pas adapter trop fréquemment
    if (now - this.lastAdaptationTime < this.adaptationCooldown) {
      return;
    }

    // Pas assez de données
    if (this.fpsHistory.length < 10) {
      return;
    }

    const qualityLevelKeys = Object.keys(this.qualityLevels);
    const currentIndex = qualityLevelKeys.indexOf(this.currentQualityLevel);

    // Déterminer si nous devons changer la qualité
    let shouldChange = false;
    let newQualityIndex = currentIndex;

    if (avgFPS < this.downgradeThreshold && currentIndex < qualityLevelKeys.length - 1) {
      // Réduire la qualité
      newQualityIndex = currentIndex + 1;
      shouldChange = true;
      // console.log(`FPS trop bas (${avgFPS.toFixed(1)}), réduction de la qualité`);
    } else if (avgFPS > this.upgradeThreshold && currentIndex > 0) {
      // Augmenter la qualité seulement si nous avons eu des performances stables pendant un moment
      if (this.stablePerformanceTime === 0) {
        this.stablePerformanceTime = now;
      } else if (now - this.stablePerformanceTime > 10000) { // 10 secondes de performance stable
        newQualityIndex = currentIndex - 1;
        shouldChange = true;
        this.stablePerformanceTime = 0;
        // console.log(`FPS stable et élevé (${avgFPS.toFixed(1)}), augmentation de la qualité`);
      }
    } else {
      // Réinitialiser le chronomètre de performance stable si le FPS chute
      this.stablePerformanceTime = 0;
    }

    // Appliquer la nouvelle qualité si nécessaire
    if (shouldChange) {
      const oldQualityLevel = this.currentQualityLevel;
      this.currentQualityLevel = qualityLevelKeys[newQualityIndex];

      // console.log(`Changement de qualité de ${oldQualityLevel} à ${this.currentQualityLevel}`);

      // Sauvegarder les paramètres d'ombre actuels avant le changement
      const previousShadowEnabled = this.qualityLevels[oldQualityLevel].shadows;
      const newShadowEnabled = this.qualityLevels[this.currentQualityLevel].shadows;

      // Appliquer les nouveaux paramètres
      this.applyCurrentQualitySettings();

      // Forcer une mise à jour complète des ombres si nous passons d'un état sans ombre à un état avec ombre
      if (!previousShadowEnabled && newShadowEnabled) {
        this.forceFullShadowUpdate();
      }

      this.lastAdaptationTime = now;

      // Effacer l'historique FPS après le changement de qualité
      this.fpsHistory = [];
    }
  }

// Nouvelle méthode pour forcer une mise à jour complète des ombres
  forceFullShadowUpdate() {
    console.log("Mise à jour complète des ombres...");

    const settings = this.qualityLevels[this.currentQualityLevel];

    // Mettre à jour les paramètres du renderer
    if (this.renderer) {
      this.renderer.shadowMap.type = settings.shadowType;
      this.renderer.shadowMap.enabled = settings.shadows;
      this.renderer.shadowMap.needsUpdate = true;
    }

    // Mettre à jour la lumière directionnelle
    if (this.world.directionalLight) {
      this.world.directionalLight.castShadow = settings.shadows;
      this.world.directionalLight.shadow.mapSize.width = settings.shadowMapSize;
      this.world.directionalLight.shadow.mapSize.height = settings.shadowMapSize;
      this.world.directionalLight.shadow.camera.far = settings.shadowDistance;

      // Forcer la mise à jour de la shadowMap
      if (this.world.directionalLight.shadow.map) {
        this.world.directionalLight.shadow.map.needsUpdate = true;
      }
    }

    // Mettre à jour tous les objets de la scène
    this.world.scene.traverse((obj) => {
      if (obj.isMesh || obj.isInstancedMesh) {
        if (obj.castShadow !== undefined) obj.castShadow = settings.shadows;
        if (obj.receiveShadow !== undefined) obj.receiveShadow = settings.shadows;

        // Forcer la mise à jour des matériaux
        if (obj.material) {
          if (Array.isArray(obj.material)) {
            obj.material.forEach(mat => {
              if (mat) mat.needsUpdate = true;
            });
          } else if (obj.material) {
            obj.material.needsUpdate = true;
          }
        }
      }

      if (obj.isLight && obj !== this.world.directionalLight && obj !== this.world.ambianLight) {
        if (obj.castShadow !== undefined) obj.castShadow = settings.shadows;
        if (obj.shadow && obj.shadow.map) {
          obj.shadow.map.needsUpdate = true;
        }
      }
    });

    // Forcer un rendu complet
    if (this.world.renderer && this.world.renderer.forceRender) {
      this.world.renderer.forceRender();
    }
  }
  // Apply the current quality settings
  applyCurrentQualitySettings() {
    const settings = this.qualityLevels[this.currentQualityLevel];

    // 1. Paramètres du renderer
    this.renderer.setPixelRatio(settings.pixelRatio * settings.renderScale);
    this.renderer.toneMapping = settings.toneMapping;
    this.renderer.toneMappingExposure = settings.toneMappingExposure;
    this.renderer.shadowMap.type = settings.shadowType;
    this.renderer.shadowMap.enabled = settings.shadows;

    // Forcer la mise à jour des shadowMaps
    this.renderer.shadowMap.needsUpdate = true;

    // 2. Paramètres de la scène
    if (this.world.scene.fog) {
      this.world.scene.fog.density = settings.fogEnabled ? settings.fogDensity : 0.0001;
    }

    // 3. Lumières
    if (this.world.ambianLight) {
      this.world.ambianLight.intensity = settings.ambientLightIntensity;
    }

    if (this.world.directionalLight) {
      this.world.directionalLight.intensity = settings.directionalLightIntensity;

      // Configurer les ombres
      if (this.world.directionalLight.shadow) {
        this.world.directionalLight.castShadow = settings.shadows;
        this.world.directionalLight.shadow.mapSize.width = settings.shadowMapSize;
        this.world.directionalLight.shadow.mapSize.height = settings.shadowMapSize;
        this.world.directionalLight.shadow.camera.far = settings.shadowDistance;
      }
    }

    // 4. Parcourir tous les objets de la scène
    this.world.scene.traverse((obj) => {
      // Activer ou désactiver les ombres sur les meshes
      if (obj.isMesh || obj.isInstancedMesh) {
        if (obj.castShadow !== undefined) {
          obj.castShadow = settings.shadows;
        }
        if (obj.receiveShadow !== undefined) {
          obj.receiveShadow = settings.shadows;
        }

        // Gérer les matériaux spéciaux comme les arbres
        if (settings.shadows && obj.name && (obj.name.includes('tree') || obj.name.includes('Tree'))) {
          if (this.world.materials && this.world.materials.list && this.world.materials.list.depthtrees) {
            obj.customDepthMaterial = this.world.materials.list.depthtrees;
          }
        }
      }

      // Gérer les lumières
      if (obj.isLight && obj !== this.world.directionalLight && obj !== this.world.ambianLight) {
        // Appliquer les paramètres d'ombre à toutes les lumières
        if (obj.shadow) {
          obj.castShadow = settings.shadows;
        }
      }
    });

    // Contrôler la visibilité des lumières en fonction de maxLights
    let activeLights = 0;
    this.world.scene.traverse((obj) => {
      if (obj.isLight && obj !== this.world.directionalLight && obj !== this.world.ambianLight) {
        obj.visible = activeLights < settings.maxLights;
        if (obj.visible) activeLights++;
      }
    });

    // 5. Ajuster le nombre d'instances pour les meshes instanciés
    this.world.scene.traverse((obj) => {
      if (obj.isInstancedMesh && obj.count > settings.maxInstanceCount) {
        obj.count = settings.maxInstanceCount;

        if (obj.castShadow !== undefined) {
          obj.castShadow = settings.shadows;
        }
        if (obj.receiveShadow !== undefined) {
          obj.receiveShadow = settings.shadows;
        }
      }
    });

    // 6. Ajuster les paramètres des nuages
    if (this.world.clouds) {
      const cloudSettings = this.world.clouds.settings;
      cloudSettings.cloudCount = Math.floor(settings.particleCount * settings.cloudDetail);

      if (settings.cloudEnabled && this.world.clouds.cloudLayers.length === 0) {
        this.world.clouds.regenerateClouds(cloudSettings.cloudCount);
      } else if (!settings.cloudEnabled && this.world.clouds.cloudLayers.length > 0) {
        this.world.clouds.cloudLayers.forEach(layer => this.world.scene.remove(layer));
        this.world.clouds.cloudLayers = [];
      }
    }

    // 7. Appliquer les paramètres de post-processing si disponibles
    if (this.world.renderer.postprocessing) {
      const post = this.world.renderer.postprocessing;

      // Bloom
      if (post.bloom) {
        post.bloom.enabled = settings.bloomEnabled;
        if (post.bloom.strength) post.bloom.strength = settings.bloomStrength;
        if (post.bloom.radius) post.bloom.radius = settings.bloomRadius;
      }

      // SSAO
      if (post.ssao) {
        post.ssao.enabled = settings.ssaoEnabled;
        if (post.ssao.radius) post.ssao.radius = settings.ssaoRadius;
        if (post.ssao.intensity) post.ssao.intensity = settings.ssaoIntensity;
      }
    }

    // 8. Appliquer les limites de qualité de texture
    this.applyTextureQualityLimits(settings.maxTextureSize, settings.anisotropy);

    // 9. Forcer une mise à jour du renderer
    if (this.world.renderer.forceRender) {
      this.world.renderer.forceRender();
    }
  }
  // Apply texture quality limits
  applyTextureQualityLimits(maxSize, anisotropy) {
    // Limiter l'anisotropie aux capacités de l'appareil
    const maxAnisotropy = Math.min(anisotropy, this.deviceCapabilities.maxAnisotropy);

    // Créer un cache pour éviter de redimensionner la même texture plusieurs fois
    const processedTextures = new WeakMap();

    this.world.scene.traverse((obj) => {
      if (obj.material) {
        // Traiter les tableaux de matériaux
        if (Array.isArray(obj.material)) {
          obj.material.forEach(material => {
            this.processTexturesInMaterial(material, maxSize, maxAnisotropy, processedTextures);
          });
        } else {
          // Traiter un matériau unique
          this.processTexturesInMaterial(obj.material, maxSize, maxAnisotropy, processedTextures);
        }
      }
    });
  }

// Nouvelle méthode auxiliaire pour traiter les textures dans un matériau
  processTexturesInMaterial(material, maxSize, maxAnisotropy, processedTextures) {
    if (!material) return;

    // Liste des propriétés de texture standard à vérifier
    const textureProperties = [
      'map', 'normalMap', 'bumpMap', 'roughnessMap', 'metalnessMap',
      'aoMap', 'emissiveMap', 'displacementMap', 'alphaMap',
      'lightMap', 'clearcoatMap', 'clearcoatNormalMap'
    ];

    // Traiter chaque texture du matériau
    textureProperties.forEach(prop => {
      const texture = material[prop];
      if (texture && texture.isTexture) {
        // Si la texture a déjà été traitée, utiliser la version traitée
        if (processedTextures.has(texture)) {
          material[prop] = processedTextures.get(texture);
          return;
        }

        // Définir l'anisotropie
        if (texture.anisotropy !== maxAnisotropy) {
          texture.anisotropy = maxAnisotropy;
          texture.needsUpdate = true;
        }

        // Limiter la taille de la texture si nécessaire et si l'image est disponible
        if (texture.image && (texture.image.width > maxSize || texture.image.height > maxSize)) {
          const ratio = maxSize / Math.max(texture.image.width, texture.image.height);
          const newWidth = Math.floor(texture.image.width * ratio);
          const newHeight = Math.floor(texture.image.height * ratio);

          try {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = newWidth;
            canvas.height = newHeight;
            ctx.drawImage(texture.image, 0, 0, newWidth, newHeight);

            const newTexture = new THREE.Texture(canvas);
            newTexture.needsUpdate = true;
            newTexture.anisotropy = maxAnisotropy;
            newTexture.encoding = texture.encoding;
            newTexture.wrapS = texture.wrapS;
            newTexture.wrapT = texture.wrapT;
            newTexture.minFilter = texture.minFilter;
            newTexture.magFilter = texture.magFilter;
            newTexture.format = texture.format;
            newTexture.type = texture.type;

            // Remplacer la texture originale
            material[prop] = newTexture;

            // Ajouter au cache
            processedTextures.set(texture, newTexture);

            // Libérer la mémoire
            texture.dispose();
          } catch (error) {
            console.error("Erreur lors du redimensionnement de la texture:", error);
          }
        } else {
          // Ajouter la texture originale au cache
          processedTextures.set(texture, texture);
        }
      }
    });

    // Marquer le matériau comme nécessitant une mise à jour
    material.needsUpdate = true;
  }
  // Manually set quality level (for UI controls)
  setQualityLevel(level) {
    if (this.qualityLevels[level]) {
      this.userQualityPreference = level;
      this.currentQualityLevel = level;
      this.applyCurrentQualitySettings();
      this.fpsHistory = []; // Clear FPS history after manual change
      
      // console.log(`Manually set quality to: ${this.qualityLevels[level].name}`);
      return true;
    }
    return false;
  }
  
  // Set quality to automatic mode
  setAutoQuality() {
    this.userQualityPreference = 'auto';
    this.fpsHistory = []; // Clear FPS history
    this.setInitialQualityLevel(); // Reset to detected level
    this.applyCurrentQualitySettings();
    
    // console.log('Set quality mode to automatic');
  }
  
  // Get current quality settings for UI display
  getCurrentQualitySettings() {
    return {
      level: this.currentQualityLevel,
      name: this.qualityLevels[this.currentQualityLevel].name,
      autoMode: this.userQualityPreference === 'auto',
      averageFPS: this.getAverageFPS()
    };
  }
  
  // Get list of available quality levels for UI
  getAvailableQualityLevels() {
    return Object.keys(this.qualityLevels).map(key => ({
      id: key,
      name: this.qualityLevels[key].name
    }));
  }
}
