<template>
  <div v-if="showBackground" class="background"></div>
  <SceneWebgl ref="sceneWebgl" @update-loading="updateLoadingState"/>
  <SceneIntroduction v-if="showIntroduction" @end-introduction="handleIntroductionEnd"/>
  <LoadingScreen v-if="loading" :progress="progress"/>
</template>

<script>
import SceneWebgl from './components/SceneWebgl.vue';
import LoadingScreen from "@/components/LoadingScreen.vue";
import SceneIntroduction from "@/components/SceneIntroduction.vue";

export default {
  name: 'App',
  data() {
    return {
      loading: true,
      progress: 0,
      showIntroduction: false,
      showBackground: true,
    };
  },
  methods: {
    updateLoadingState({loading, progress}) {
      this.loading = loading;
      this.progress = progress;

      if (!loading) {
        this.showIntroduction = true;
        // if (window.location.href.includes("#dev")) {
        //   setTimeout(() => {
        //     this.showBackground = false;
        //   }, 500);
        // } else {
          setTimeout(() => {
            this.showBackground = false;
          }, 6000);
        // }
      }
    },
    handleIntroductionEnd() {
      this.showIntroduction = false;
      setTimeout(() => {
        this.triggerZoomIn();
      }, 300);
    },
    triggerZoomIn() {
      this.$refs.sceneWebgl.zoomIn();
    }

  },
  components: {
    SceneIntroduction,
    LoadingScreen,
    SceneWebgl,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Vinci Sans";
  src: url('@/assets/fonts/VinciSans.woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Vinci Sans";
  src: url('@/assets/fonts/VinciSans-Medium.woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Vinci Sans";
  src: url('@/assets/fonts/VinciSans-Bold.woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Vinci Sans Expanded";
  src: url('@/assets/fonts/VinciSansExpanded-Bold.woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


html,
body {
  margin: 0;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: "Vinci Sans", sans-serif;

  h2, h3, h4, h5, h6 {
    margin: 0;
  }
}

.swiper-container {
  position: relative;
  height: 100%;

  .arrows {
    position: absolute;
    bottom: 0;
    right: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 0 1rem;
    z-index: 10;

    .arrow {
      display: flex;
      width: 3rem;
      height: 3rem;
      background-color: #fff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s ease-out, transform 0.3s ease-in-out;
      background-image: url(@/assets/svg/arrow.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 40%;

      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
      }

      &.prev {
        transform: rotate(180deg);
      }
      &.swiper-button-disabled {
        background-color: rgba(255, 255, 255, 0.7);
        cursor: default;
      }
    }
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: opacity 0.3s ease-out;

}

.popin {
  position: absolute;
  inset: 1rem;
  max-width: 99%;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;

  &.show {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s ease-out 1.5s;
  }

  @media screen and (min-width: 768px) {
    inset: unset;
    right: 1rem;
    bottom: 1rem;
    height: 50rem;
    max-height: 60vh;
  }

  .popin-container {
    display: flex;
    flex-direction: column;
    width: 25rem;
    max-width: 90%;
    height: 100%;
    padding: 0 1.2rem 0 1.2rem;
    border-radius: 1.2rem;
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.7);
    overflow: hidden;

    .swiper {
      flex: 1;
      width: 100%;
      height: 100%;

      .swiper-wrapper,
      .swiper-slide {
        height: 100%;
      }
    }

    .popin-close {
      cursor: pointer;
      // reset button styles
      appearance: none;
      padding: 0;
      margin: 0;
      font-size: 0;
      width: 1.5rem;
      height: 1.5rem;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 1px solid;
      border-color: rgba(#004289, 0.2);
      // faire la crois en bleu foncé
      background-color: transparent;
      background-image: url(@/assets/svg/cross.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50%;
      transition: border-color 0.3s ease-out;

      &:hover {
        border-color: #004289;
      }
    }
  }
}

.popin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  width: 100%;
  font-family: "Vinci Sans Expanded", sans-serif;
  padding: 1rem 0;
  color: $color-primary;

  h2 {
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }
}

.popin-content {
  overflow: auto;
  height: 100%;
  scrollbar-width: none;
}


.popin-text {
  background-color: #fff;
  color: $color-primary;
  padding: 1rem;
  border-radius: 0.75rem;
  margin-bottom: 1.2rem;

  h2 {
    display: none;

  }
  h3 {
    font-family: "Vinci Sans Expanded", sans-serif;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1em;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 0.5em;

    &::before {
      color: #E40520;
      content: ". ";
    }
  }

  p + h3,
  p + h4,
  ul + h3,
  ul + h4 {
    margin-top: 1em;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin: 0;
    padding: 0;

    li {
      list-style-position: outside;
      margin: 0;
      position: relative;
      border-radius: 0.75rem;
      padding: 0.3rem 0 0.3rem;
      margin-left: 0.66rem;
    }
  }
  ul.checkmark {
    list-style-type: none;
    li {
      margin-left: 0;
      padding: 0.7rem 1rem 0.7rem 2rem;
      background-color: $color-primary;
      color: #fff;
      list-style-position: outside;
      &::before {
        content: "";
        width: 0.7em;
        height: 0.7em;
        position: absolute;
        left: 0.7rem;
        top: 0.8rem;
        background-image: url(@/assets/svg/checkmark-white.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: inline-block;

      }
    }
  }

  &.popin-text-dark {
    background-color: $color-primary;
    color: #fff;

    ul.checkmark {
      li {

        background-color: #fff;
        color: $color-primary;
        &::before {
          background-image: url(@/assets/svg/checkmark-blue.svg);
        }
      }
    }
  }

  a {
    font-family: "Vinci Sans Expanded", sans-serif;
    font-size: 0.85rem;
    color: $color-primary;
  }
}

.area-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Vinci Sans Expanded", sans-serif;
  font-weight: bold;
  color: $color-primary;
  font-size: 14px;
  background-color: rgba(white, 0.5);
  padding: 13px 18px;
  border-radius: 100px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out;

  &.active {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }

  &::after {
    content: '';
    position: absolute;
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    top: 3px;
    left: 3px;
    border-radius: 100px;
    background-color: white;
    z-index: 1;
  }

  .area-tooltip-inner {
    position: relative;
    z-index: 2;
  }
}

.area-tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Vinci Sans Expanded", sans-serif;
  font-weight: bold;
  color: $color-primary;
  font-size: 14px;
  background-color: rgba(white, 0.5);
  padding: 13px 18px;
  border-radius: 100px;
  opacity: 0;
  visibility: hidden;

  transition: opacity 0.2s ease-out;

  &.active {

    .intro-finished & {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s ease-out;
    }
  }

  &::after {
    content: '';
    position: absolute;
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    top: 3px;
    left: 3px;
    border-radius: 100px;
    background-color: white;
    z-index: 1;
  }

  .area-tooltip-inner {
    position: relative;
    z-index: 2;
  }

}
</style>
