import * as THREE from "three";

export default class Materials {
  constructor(world) {
    this.world = world;

    this.uniforms = {
      uTime: { value: 0 },
      uBpColor: { value: new THREE.Color(0x3464b4) },
      uTest: { value: 0.111 },
      uTest2: { value: 0.033 },
      uTest3: { value: -0.047 },
      uTest4: { value: -0.076 },
      uSpriteFade: { value: 0 },
    };
    this.list = {
      color: new THREE.MeshStandardMaterial({
        map: this.world.assets.textures.color,
      }),
      trees: new THREE.MeshStandardMaterial({
        map: this.world.assets.textures.color,
      }),
      depthtrees: new THREE.MeshDepthMaterial({
        depthPacking: THREE.RGBADepthPacking,
      }),
      blueprint: new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 0.4,
      }),
      blueprintOutline: new THREE.MeshBasicMaterial(),
      wind: new THREE.ShaderMaterial({
        side: THREE.DoubleSide,
        transparent: true,
        depthWrite: false,
      }),

      water: new THREE.MeshStandardMaterial(),
      waterNoBorder: new THREE.MeshStandardMaterial(),
      area: new THREE.SpriteMaterial({
        sizeAttenuation: false,
        map: this.world.assets.textures.color,
        depthWrite: false,
        depthTest: false,
      }),
      subArea: new THREE.SpriteMaterial({
        sizeAttenuation: false,
        map: this.world.assets.textures.color,
        depthWrite: false,
        depthTest: false,
      }),

    };


    this.cloudShadow();
    this.trees();
    this.wind();
    this.water();
    this.waterNoBorder();
    this.area();
    this.radioWaves();
    this.droneWaves()
  }

  cloudShadow() {
    for (let material of ["color"]) {
      this.list[material].onBeforeCompile = (shader) => {
        shader.uniforms.uCloudShadow = {
          value: this.world.assets.textures.cloudShadow,
        };
        shader.uniforms.uTime = this.uniforms.uTime;
        shader.uniforms.uTest = this.uniforms.uTest;
      };
    }
    THREE.ShaderChunk.common =
      THREE.ShaderChunk.common +
      `
      #if defined USE_FOG || defined LAMBERT
        varying vec3 vWSurfacePos;
        uniform float uTime;
        uniform sampler2D uCloudShadow;
        uniform float uTest;
      #endif
    `;
    THREE.ShaderChunk.begin_vertex =
      THREE.ShaderChunk.begin_vertex +
      `
      #if defined USE_FOG || defined LAMBERT
        #if defined USE_INSTANCING
          vWSurfacePos = vec3(modelMatrix * instanceMatrix * vec4(position, 1.));
        #else
          vWSurfacePos = (modelMatrix * vec4(position, 1.0)).xyz;
        #endif
      #endif
    `;
    THREE.ShaderChunk.lights_physical_pars_fragment = `
    struct PhysicalMaterial {

      vec3 diffuseColor;
      float roughness;
      vec3 specularColor;
      float specularF90;
      float dispersion;

      #ifdef USE_CLEARCOAT
        float clearcoat;
        float clearcoatRoughness;
        vec3 clearcoatF0;
        float clearcoatF90;
      #endif

      #ifdef USE_IRIDESCENCE
        float iridescence;
        float iridescenceIOR;
        float iridescenceThickness;
        vec3 iridescenceFresnel;
        vec3 iridescenceF0;
      #endif

      #ifdef USE_SHEEN
        vec3 sheenColor;
        float sheenRoughness;
      #endif

      #ifdef IOR
        float ior;
      #endif

      #ifdef USE_TRANSMISSION
        float transmission;
        float transmissionAlpha;
        float thickness;
        float attenuationDistance;
        vec3 attenuationColor;
      #endif

      #ifdef USE_ANISOTROPY
        float anisotropy;
        float alphaT;
        vec3 anisotropyT;
        vec3 anisotropyB;
      #endif

    };

    float cloud(){
      return 1.0 - max(texture(uCloudShadow, (vWSurfacePos.xz * 0.01) + vec2(-uTime * 0.025, uTime * 0.005)).r - 0.25, 0.0);
    }

    // temporary
    vec3 clearcoatSpecularDirect = vec3( 0.0 );
    vec3 clearcoatSpecularIndirect = vec3( 0.0 );
    vec3 sheenSpecularDirect = vec3( 0.0 );
    vec3 sheenSpecularIndirect = vec3(0.0 );

    vec3 Schlick_to_F0( const in vec3 f, const in float f90, const in float dotVH ) {
        float x = clamp( 1.0 - dotVH, 0.0, 1.0 );
        float x2 = x * x;
        float x5 = clamp( x * x2 * x2, 0.0, 0.9999 );

        return ( f - vec3( f90 ) * x5 ) / ( 1.0 - x5 );
    }

    // Moving Frostbite to Physically Based Rendering 3.0 - page 12, listing 2
    // https://seblagarde.files.wordpress.com/2015/07/course_notes_moving_frostbite_to_pbr_v32.pdf
    float V_GGX_SmithCorrelated( const in float alpha, const in float dotNL, const in float dotNV ) {

      float a2 = pow2( alpha );

      float gv = dotNL * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNV ) );
      float gl = dotNV * sqrt( a2 + ( 1.0 - a2 ) * pow2( dotNL ) );

      return 0.5 / max( gv + gl, EPSILON );

    }

    // Microfacet Models for Refraction through Rough Surfaces - equation (33)
    // http://graphicrants.blogspot.com/2013/08/specular-brdf-reference.html
    // alpha is "roughness squared" in Disney’s reparameterization
    float D_GGX( const in float alpha, const in float dotNH ) {

      float a2 = pow2( alpha );

      float denom = pow2( dotNH ) * ( a2 - 1.0 ) + 1.0; // avoid alpha = 0 with dotNH = 1

      return RECIPROCAL_PI * a2 / pow2( denom );

    }

    // https://google.github.io/filament/Filament.md.html#materialsystem/anisotropicmodel/anisotropicspecularbrdf
    #ifdef USE_ANISOTROPY

      float V_GGX_SmithCorrelated_Anisotropic( const in float alphaT, const in float alphaB, const in float dotTV, const in float dotBV, const in float dotTL, const in float dotBL, const in float dotNV, const in float dotNL ) {

        float gv = dotNL * length( vec3( alphaT * dotTV, alphaB * dotBV, dotNV ) );
        float gl = dotNV * length( vec3( alphaT * dotTL, alphaB * dotBL, dotNL ) );
        float v = 0.5 / ( gv + gl );

        return saturate(v);

      }

      float D_GGX_Anisotropic( const in float alphaT, const in float alphaB, const in float dotNH, const in float dotTH, const in float dotBH ) {

        float a2 = alphaT * alphaB;
        highp vec3 v = vec3( alphaB * dotTH, alphaT * dotBH, a2 * dotNH );
        highp float v2 = dot( v, v );
        float w2 = a2 / v2;

        return RECIPROCAL_PI * a2 * pow2 ( w2 );

      }

    #endif

    #ifdef USE_CLEARCOAT

      // GGX Distribution, Schlick Fresnel, GGX_SmithCorrelated Visibility
      vec3 BRDF_GGX_Clearcoat( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in PhysicalMaterial material) {

        vec3 f0 = material.clearcoatF0;
        float f90 = material.clearcoatF90;
        float roughness = material.clearcoatRoughness;

        float alpha = pow2( roughness ); // UE4's roughness

        vec3 halfDir = normalize( lightDir + viewDir );

        float dotNL = saturate( dot( normal, lightDir ) );
        float dotNV = saturate( dot( normal, viewDir ) );
        float dotNH = saturate( dot( normal, halfDir ) );
        float dotVH = saturate( dot( viewDir, halfDir ) );
      dotNL *= dotNL > 0.0 ? cloud() : 1.0;

        vec3 F = F_Schlick( f0, f90, dotVH );

        float V = V_GGX_SmithCorrelated( alpha, dotNL, dotNV );

        float D = D_GGX( alpha, dotNH );

        return F * ( V * D );

      }

    #endif

    vec3 BRDF_GGX( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, const in PhysicalMaterial material ) {

      vec3 f0 = material.specularColor;
      float f90 = material.specularF90;
      float roughness = material.roughness;

      float alpha = pow2( roughness ); // UE4's roughness

      vec3 halfDir = normalize( lightDir + viewDir );

      float dotNL = saturate( dot( normal, lightDir ) );
      float dotNV = saturate( dot( normal, viewDir ) );
      float dotNH = saturate( dot( normal, halfDir ) );
      float dotVH = saturate( dot( viewDir, halfDir ) );
      dotNL *= dotNL > 0.0 ? cloud() : 1.0;

      vec3 F = F_Schlick( f0, f90, dotVH );

      #ifdef USE_IRIDESCENCE

        F = mix( F, material.iridescenceFresnel, material.iridescence );

      #endif

      #ifdef USE_ANISOTROPY

        float dotTL = dot( material.anisotropyT, lightDir );
        float dotTV = dot( material.anisotropyT, viewDir );
        float dotTH = dot( material.anisotropyT, halfDir );
        float dotBL = dot( material.anisotropyB, lightDir );
        float dotBV = dot( material.anisotropyB, viewDir );
        float dotBH = dot( material.anisotropyB, halfDir );

        float V = V_GGX_SmithCorrelated_Anisotropic( material.alphaT, alpha, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL );

        float D = D_GGX_Anisotropic( material.alphaT, alpha, dotNH, dotTH, dotBH );

      #else

        float V = V_GGX_SmithCorrelated( alpha, dotNL, dotNV );

        float D = D_GGX( alpha, dotNH );

      #endif

      return F * ( V * D );

    }

    // Rect Area Light

    // Real-Time Polygonal-Light Shading with Linearly Transformed Cosines
    // by Eric Heitz, Jonathan Dupuy, Stephen Hill and David Neubelt
    // code: https://github.com/selfshadow/ltc_code/

    vec2 LTC_Uv( const in vec3 N, const in vec3 V, const in float roughness ) {

      const float LUT_SIZE = 64.0;
      const float LUT_SCALE = ( LUT_SIZE - 1.0 ) / LUT_SIZE;
      const float LUT_BIAS = 0.5 / LUT_SIZE;

      float dotNV = saturate( dot( N, V ) );

      // texture parameterized by sqrt( GGX alpha ) and sqrt( 1 - cos( theta ) )
      vec2 uv = vec2( roughness, sqrt( 1.0 - dotNV ) );

      uv = uv * LUT_SCALE + LUT_BIAS;

      return uv;

    }

    float LTC_ClippedSphereFormFactor( const in vec3 f ) {

      // Real-Time Area Lighting: a Journey from Research to Production (p.102)
      // An approximation of the form factor of a horizon-clipped rectangle.

      float l = length( f );

      return max( ( l * l + f.z ) / ( l + 1.0 ), 0.0 );

    }

    vec3 LTC_EdgeVectorFormFactor( const in vec3 v1, const in vec3 v2 ) {

      float x = dot( v1, v2 );

      float y = abs( x );

      // rational polynomial approximation to theta / sin( theta ) / 2PI
      float a = 0.8543985 + ( 0.4965155 + 0.0145206 * y ) * y;
      float b = 3.4175940 + ( 4.1616724 + y ) * y;
      float v = a / b;

      float theta_sintheta = ( x > 0.0 ) ? v : 0.5 * inversesqrt( max( 1.0 - x * x, 1e-7 ) ) - v;

      return cross( v1, v2 ) * theta_sintheta;

    }

    vec3 LTC_Evaluate( const in vec3 N, const in vec3 V, const in vec3 P, const in mat3 mInv, const in vec3 rectCoords[ 4 ] ) {

      // bail if point is on back side of plane of light
      // assumes ccw winding order of light vertices
      vec3 v1 = rectCoords[ 1 ] - rectCoords[ 0 ];
      vec3 v2 = rectCoords[ 3 ] - rectCoords[ 0 ];
      vec3 lightNormal = cross( v1, v2 );

      if( dot( lightNormal, P - rectCoords[ 0 ] ) < 0.0 ) return vec3( 0.0 );

      // construct orthonormal basis around N
      vec3 T1, T2;
      T1 = normalize( V - N * dot( V, N ) );
      T2 = - cross( N, T1 ); // negated from paper; possibly due to a different handedness of world coordinate system

      // compute transform
      mat3 mat = mInv * transposeMat3( mat3( T1, T2, N ) );

      // transform rect
      vec3 coords[ 4 ];
      coords[ 0 ] = mat * ( rectCoords[ 0 ] - P );
      coords[ 1 ] = mat * ( rectCoords[ 1 ] - P );
      coords[ 2 ] = mat * ( rectCoords[ 2 ] - P );
      coords[ 3 ] = mat * ( rectCoords[ 3 ] - P );

      // project rect onto sphere
      coords[ 0 ] = normalize( coords[ 0 ] );
      coords[ 1 ] = normalize( coords[ 1 ] );
      coords[ 2 ] = normalize( coords[ 2 ] );
      coords[ 3 ] = normalize( coords[ 3 ] );

      // calculate vector form factor
      vec3 vectorFormFactor = vec3( 0.0 );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 0 ], coords[ 1 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 1 ], coords[ 2 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 2 ], coords[ 3 ] );
      vectorFormFactor += LTC_EdgeVectorFormFactor( coords[ 3 ], coords[ 0 ] );

      // adjust for horizon clipping
      float result = LTC_ClippedSphereFormFactor( vectorFormFactor );

    /*
      // alternate method of adjusting for horizon clipping (see referece)
      // refactoring required
      float len = length( vectorFormFactor );
      float z = vectorFormFactor.z / len;

      const float LUT_SIZE = 64.0;
      const float LUT_SCALE = ( LUT_SIZE - 1.0 ) / LUT_SIZE;
      const float LUT_BIAS = 0.5 / LUT_SIZE;

      // tabulated horizon-clipped sphere, apparently...
      vec2 uv = vec2( z * 0.5 + 0.5, len );
      uv = uv * LUT_SCALE + LUT_BIAS;

      float scale = texture2D( ltc_2, uv ).w;

      float result = len * scale;
    */

      return vec3( result );

    }

    // End Rect Area Light

    #if defined( USE_SHEEN )

    // https://github.com/google/filament/blob/master/shaders/src/brdf.fs
    float D_Charlie( float roughness, float dotNH ) {

      float alpha = pow2( roughness );

      // Estevez and Kulla 2017, "Production Friendly Microfacet Sheen BRDF"
      float invAlpha = 1.0 / alpha;
      float cos2h = dotNH * dotNH;
      float sin2h = max( 1.0 - cos2h, 0.0078125 ); // 2^(-14/2), so sin2h^2 > 0 in fp16

      return ( 2.0 + invAlpha ) * pow( sin2h, invAlpha * 0.5 ) / ( 2.0 * PI );

    }

    // https://github.com/google/filament/blob/master/shaders/src/brdf.fs
    float V_Neubelt( float dotNV, float dotNL ) {

      // Neubelt and Pettineo 2013, "Crafting a Next-gen Material Pipeline for The Order: 1886"
      return saturate( 1.0 / ( 4.0 * ( dotNL + dotNV - dotNL * dotNV ) ) );

    }

    vec3 BRDF_Sheen( const in vec3 lightDir, const in vec3 viewDir, const in vec3 normal, vec3 sheenColor, const in float sheenRoughness ) {

      vec3 halfDir = normalize( lightDir + viewDir );

      float dotNL = saturate( dot( normal, lightDir ) );
      float dotNV = saturate( dot( normal, viewDir ) );
      float dotNH = saturate( dot( normal, halfDir ) );
      dotNL *= dotNL > 0.0 ? cloud() : 1.0;

      float D = D_Charlie( sheenRoughness, dotNH );
      float V = V_Neubelt( dotNV, dotNL );

      return sheenColor * ( D * V );

    }

    #endif

    // This is a curve-fit approxmation to the "Charlie sheen" BRDF integrated over the hemisphere from 
    // Estevez and Kulla 2017, "Production Friendly Microfacet Sheen BRDF". The analysis can be found
    // in the Sheen section of https://drive.google.com/file/d/1T0D1VSyR4AllqIJTQAraEIzjlb5h4FKH/view?usp=sharing
    float IBLSheenBRDF( const in vec3 normal, const in vec3 viewDir, const in float roughness ) {

      float dotNV = saturate( dot( normal, viewDir ) );

      float r2 = roughness * roughness;

      float a = roughness < 0.25 ? -339.2 * r2 + 161.4 * roughness - 25.9 : -8.48 * r2 + 14.3 * roughness - 9.95;

      float b = roughness < 0.25 ? 44.0 * r2 - 23.7 * roughness + 3.26 : 1.97 * r2 - 3.27 * roughness + 0.72;

      float DG = exp( a * dotNV + b ) + ( roughness < 0.25 ? 0.0 : 0.1 * ( roughness - 0.25 ) );

      return saturate( DG * RECIPROCAL_PI );

    }

    // Analytical approximation of the DFG LUT, one half of the
    // split-sum approximation used in indirect specular lighting.
    // via 'environmentBRDF' from "Physically Based Shading on Mobile"
    // https://www.unrealengine.com/blog/physically-based-shading-on-mobile
    vec2 DFGApprox( const in vec3 normal, const in vec3 viewDir, const in float roughness ) {

      float dotNV = saturate( dot( normal, viewDir ) );

      const vec4 c0 = vec4( - 1, - 0.0275, - 0.572, 0.022 );

      const vec4 c1 = vec4( 1, 0.0425, 1.04, - 0.04 );

      vec4 r = roughness * c0 + c1;

      float a004 = min( r.x * r.x, exp2( - 9.28 * dotNV ) ) * r.x + r.y;

      vec2 fab = vec2( - 1.04, 1.04 ) * a004 + r.zw;

      return fab;

    }

    vec3 EnvironmentBRDF( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float roughness ) {

      vec2 fab = DFGApprox( normal, viewDir, roughness );

      return specularColor * fab.x + specularF90 * fab.y;

    }

    // Fdez-Agüera's "Multiple-Scattering Microfacet Model for Real-Time Image Based Lighting"
    // Approximates multiscattering in order to preserve energy.
    // http://www.jcgt.org/published/0008/01/03/
    #ifdef USE_IRIDESCENCE
    void computeMultiscatteringIridescence( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float iridescence, const in vec3 iridescenceF0, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
    #else
    void computeMultiscattering( const in vec3 normal, const in vec3 viewDir, const in vec3 specularColor, const in float specularF90, const in float roughness, inout vec3 singleScatter, inout vec3 multiScatter ) {
    #endif

      vec2 fab = DFGApprox( normal, viewDir, roughness );

      #ifdef USE_IRIDESCENCE

        vec3 Fr = mix( specularColor, iridescenceF0, iridescence );

      #else

        vec3 Fr = specularColor;

      #endif

      vec3 FssEss = Fr * fab.x + specularF90 * fab.y;

      float Ess = fab.x + fab.y;
      float Ems = 1.0 - Ess;

      vec3 Favg = Fr + ( 1.0 - Fr ) * 0.047619; // 1/21
      vec3 Fms = FssEss * Favg / ( 1.0 - Ems * Favg );

      singleScatter += FssEss;
      multiScatter += Fms * Ems;

    }

    #if NUM_RECT_AREA_LIGHTS > 0

      void RE_Direct_RectArea_Physical( const in RectAreaLight rectAreaLight, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in vec3 geometryClearcoatNormal, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {

        vec3 normal = geometryNormal;
        vec3 viewDir = geometryViewDir;
        vec3 position = geometryPosition;
        vec3 lightPos = rectAreaLight.position;
        vec3 halfWidth = rectAreaLight.halfWidth;
        vec3 halfHeight = rectAreaLight.halfHeight;
        vec3 lightColor = rectAreaLight.color;
        float roughness = material.roughness;

        vec3 rectCoords[ 4 ];
        rectCoords[ 0 ] = lightPos + halfWidth - halfHeight; // counterclockwise; light shines in local neg z direction
        rectCoords[ 1 ] = lightPos - halfWidth - halfHeight;
        rectCoords[ 2 ] = lightPos - halfWidth + halfHeight;
        rectCoords[ 3 ] = lightPos + halfWidth + halfHeight;

        vec2 uv = LTC_Uv( normal, viewDir, roughness );

        vec4 t1 = texture2D( ltc_1, uv );
        vec4 t2 = texture2D( ltc_2, uv );

        mat3 mInv = mat3(
          vec3( t1.x, 0, t1.y ),
          vec3(    0, 1,    0 ),
          vec3( t1.z, 0, t1.w )
        );

        // LTC Fresnel Approximation by Stephen Hill
        // http://blog.selfshadow.com/publications/s2016-advances/s2016_ltc_fresnel.pdf
        vec3 fresnel = ( material.specularColor * t2.x + ( vec3( 1.0 ) - material.specularColor ) * t2.y );

        reflectedLight.directSpecular += lightColor * fresnel * LTC_Evaluate( normal, viewDir, position, mInv, rectCoords );

        reflectedLight.directDiffuse += lightColor * material.diffuseColor * LTC_Evaluate( normal, viewDir, position, mat3( 1.0 ), rectCoords );

      }

    #endif

    void RE_Direct_Physical( const in IncidentLight directLight, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in vec3 geometryClearcoatNormal, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {

      float dotNL = saturate( dot( geometryNormal, directLight.direction ) );
      dotNL *= dotNL > 0.0 ? cloud() : 1.0;

      vec3 irradiance = dotNL * directLight.color;

      #ifdef USE_CLEARCOAT

        float dotNLcc = saturate( dot( geometryClearcoatNormal, directLight.direction ) );

        vec3 ccIrradiance = dotNLcc * directLight.color;

        clearcoatSpecularDirect += ccIrradiance * BRDF_GGX_Clearcoat( directLight.direction, geometryViewDir, geometryClearcoatNormal, material );

      #endif

      #ifdef USE_SHEEN

        sheenSpecularDirect += irradiance * BRDF_Sheen( directLight.direction, geometryViewDir, geometryNormal, material.sheenColor, material.sheenRoughness );

      #endif

      reflectedLight.directSpecular += irradiance * BRDF_GGX( directLight.direction, geometryViewDir, geometryNormal, material );

      reflectedLight.directDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );
    }

    void RE_IndirectDiffuse_Physical( const in vec3 irradiance, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in vec3 geometryClearcoatNormal, const in PhysicalMaterial material, inout ReflectedLight reflectedLight ) {

      reflectedLight.indirectDiffuse += irradiance * BRDF_Lambert( material.diffuseColor );

    }

    void RE_IndirectSpecular_Physical( const in vec3 radiance, const in vec3 irradiance, const in vec3 clearcoatRadiance, const in vec3 geometryPosition, const in vec3 geometryNormal, const in vec3 geometryViewDir, const in vec3 geometryClearcoatNormal, const in PhysicalMaterial material, inout ReflectedLight reflectedLight) {

      #ifdef USE_CLEARCOAT

        clearcoatSpecularIndirect += clearcoatRadiance * EnvironmentBRDF( geometryClearcoatNormal, geometryViewDir, material.clearcoatF0, material.clearcoatF90, material.clearcoatRoughness );

      #endif

      #ifdef USE_SHEEN

        sheenSpecularIndirect += irradiance * material.sheenColor * IBLSheenBRDF( geometryNormal, geometryViewDir, material.sheenRoughness );

      #endif

      // Both indirect specular and indirect diffuse light accumulate here

      vec3 singleScattering = vec3( 0.0 );
      vec3 multiScattering = vec3( 0.0 );
      vec3 cosineWeightedIrradiance = irradiance * RECIPROCAL_PI;

      #ifdef USE_IRIDESCENCE

        computeMultiscatteringIridescence( geometryNormal, geometryViewDir, material.specularColor, material.specularF90, material.iridescence, material.iridescenceFresnel, material.roughness, singleScattering, multiScattering );

      #else

        computeMultiscattering( geometryNormal, geometryViewDir, material.specularColor, material.specularF90, material.roughness, singleScattering, multiScattering );

      #endif

      vec3 totalScattering = singleScattering + multiScattering;
      vec3 diffuse = material.diffuseColor * ( 1.0 - max( max( totalScattering.r, totalScattering.g ), totalScattering.b ) );

      reflectedLight.indirectSpecular += radiance * singleScattering;
      reflectedLight.indirectSpecular += multiScattering * cosineWeightedIrradiance;

      reflectedLight.indirectDiffuse += diffuse * cosineWeightedIrradiance;

    }

    #define RE_Direct				RE_Direct_Physical
    #define RE_Direct_RectArea		RE_Direct_RectArea_Physical
    #define RE_IndirectDiffuse		RE_IndirectDiffuse_Physical
    #define RE_IndirectSpecular		RE_IndirectSpecular_Physical

    // ref: https://seblagarde.files.wordpress.com/2015/07/course_notes_moving_frostbite_to_pbr_v32.pdf
    float computeSpecularOcclusion( const in float dotNV, const in float ambientOcclusion, const in float roughness ) {

      return saturate( pow( dotNV + ambientOcclusion, exp2( - 16.0 * roughness - 1.0 ) ) - 1.0 + ambientOcclusion );

    }
  `;
  }

  trees() {
    const vertex = `
    #include <begin_vertex>
    // Le code suivant est commenté pour désactiver l'animation
    // float wind = sin(uTime * 0.5 + (aHeight + vWSurfacePos.x * 0.1)) * (aHeight + position.y) * 0.1;
    // transformed.x += wind;
    // transformed.z += wind * 0.5;
  `;
    this.list.trees.onBeforeCompile = (shader) => {
      shader.uniforms.uCloud = { value: this.world.assets.cloud };
      shader.uniforms.uTime = this.uniforms.uTime;

      shader.vertexShader = shader.vertexShader.replace(
          "#include <common>",
          `
        #include <common>
        attribute float aHeight;
      `
      );
      shader.vertexShader = shader.vertexShader.replace(
          "#include <begin_vertex>",
          vertex
      );
    };
    this.list.depthtrees.onBeforeCompile = (shader) => {
      shader.uniforms.uTime = this.uniforms.uTime;
      shader.vertexShader = shader.vertexShader.replace(
          "#include <common>",
          `
        #include <common>
        uniform float uTime;
        attribute float aHeight;
      `
      );
      shader.vertexShader = shader.vertexShader.replace(
          "#include <begin_vertex>",
          `
      vec3 vWSurfacePos = vec3(modelMatrix * instanceMatrix * vec4(position, 1.));
     ` + vertex
      );
    };
  }

  wind() {
    this.list.wind.uniforms.uTime = this.uniforms.uTime;
    this.list.wind.vertexShader = `
    uniform float uTime;
    attribute vec3 aPos;
    varying vec2 vUv;
    #define DISTANCE ${100 + ".0"}
    varying float vOpacity;

    float rand(float n){return fract(sin(n) * 43758.5453123);}

    void main()
    {
      // Réduire la vitesse et la complexité du vent
      float speed = uTime * (1.0 + rand(aPos.z) * 8.0);

      float hDist = DISTANCE * 0.5;
      float xStep = mod(aPos.x + speed + hDist, DISTANCE);
      vec3 finalPos = vec3(xStep - hDist, aPos.yz) + position;

      // Distance fade simplifiée
      float d = 1.0 - (distance(xStep + position.x, hDist) / hDist);
      vOpacity = d * 0.4; // Réduire légèrement l'opacité pour alléger le rendu

      gl_Position = projectionMatrix * modelViewMatrix * vec4(finalPos, 1.0);
      vUv = uv;
    }
  `;
    this.list.wind.fragmentShader = `
    varying vec2 vUv;
    varying float vOpacity;

    void main()
    {
      // Simplifier le calcul du fragment shader
      float alpha = vOpacity * (1.0 - distance(vUv.x, 0.5) * 2.0);
      
      // Optimisation: seuil minimum pour éviter les calculs sur pixels quasi-transparents
      if (alpha < 0.01) discard;
      
      gl_FragColor = vec4(1.0, 1.0, 1.0, alpha);
    }
  `;
  }

  water() {
    this.list.water.onBeforeCompile = (shader) => {
      shader.uniforms.uTest = this.uniforms.uTest;
      shader.uniforms.uTime = this.uniforms.uTime;
      shader.uniforms.uCloudShadow = {
        value: this.world.assets.textures.cloudshadow,
      };

      shader.uniforms.uClouds = { value: this.world.assets.textures.clouds };
      shader.uniforms.uMap = { value: this.world.assets.textures.water };
      shader.uniforms.uWaterMap = {
        value: this.world.assets.textures.watermap,
      };
      shader.uniforms.uCameraPosition = { value: this.world.camera.position };

      shader.vertexShader = shader.vertexShader.replace(
          "#include <common>",
          `
        #include <common>
        varying vec2 vUv;
        varying vec2 wPos;
      `
      );
      shader.vertexShader = shader.vertexShader.replace(
          "#include <begin_vertex>",
          `
        #include <begin_vertex>
        vUv = uv;
        wPos = position.xz;
      `
      );
      shader.fragmentShader = shader.fragmentShader.replace(
          "#include <common>",
          `
        #include <common>
        uniform sampler2D uMap;
        uniform sampler2D uWaterMap;
        uniform sampler2D uClouds;

        uniform vec3 uCameraPosition;

        varying vec2 vUv;
        varying vec2 wPos;
      `
      );
      shader.fragmentShader = shader.fragmentShader.replace(
          "vec4 diffuseColor = vec4( diffuse, opacity );",
          `
        // Réduire la fréquence des animations d'eau pour améliorer les performances
        float uvNoise = texture(uMap, vUv - vec2(0.0, -uTime * 0.05)).r * 0.05;
        vec2 cameraOffset = uCameraPosition.xz * 0.01;
        vec3 color = texture(uClouds, wPos * 0.1 + vec2(-uTime * 0.025, 0.0) + uvNoise + cameraOffset).rgb;

        vec2 waterMapUV = vec2(wPos.x * 0.11, -wPos.y * 0.033) + vec2(0.032, -0.079);
        float edgeHeight = texture(uWaterMap, waterMapUV).r;
        float edgeNoise = texture(uMap, vUv - vec2(0.0, -uTime * 0.0125)).r;

        color = edgeNoise < edgeHeight ? vec3(1.0) : color * 1.5;

        vec4 diffuseColor = vec4(color, opacity);
      `
      );
    };
  }

  waterNoBorder() {
    this.list.waterNoBorder.onBeforeCompile = (shader) => {
      shader.uniforms.uTime = this.uniforms.uTime;
      shader.uniforms.uCloudShadow = {
        value: this.world.assets.textures.cloudshadow,
      };
      shader.uniforms.uClouds = { value: this.world.assets.textures.clouds };
      shader.uniforms.uMap = { value: this.world.assets.textures.water };
      shader.uniforms.uCameraPosition = { value: this.world.camera.position };

      shader.vertexShader = shader.vertexShader.replace(
          "#include <common>",
          `
        #include <common>
        varying vec2 vUv;
        varying vec2 wPos;
      `
      );
      shader.vertexShader = shader.vertexShader.replace(
          "#include <begin_vertex>",
          `
        #include <begin_vertex>
        vUv = uv;
        wPos = position.xz;
      `
      );
      shader.fragmentShader = shader.fragmentShader.replace(
          "#include <common>",
          `
        #include <common>
        uniform sampler2D uMap;
        uniform sampler2D uClouds;

        uniform vec3 uCameraPosition;

        varying vec2 vUv;
        varying vec2 wPos;
      `
      );
      shader.fragmentShader = shader.fragmentShader.replace(
          "vec4 diffuseColor = vec4( diffuse, opacity );",
          `
        // Réduire la fréquence des animations d'eau pour améliorer les performances
        float uvNoise = texture(uMap, vUv - vec2(0.0, -uTime * 0.05)).r * 0.05;
        vec2 cameraOffset = uCameraPosition.xz * 0.01;
        vec3 color = texture(uClouds, wPos * 0.1 + vec2(-uTime * 0.025, 0.0) + uvNoise + cameraOffset).rgb;

        color *= 1.5;

        vec4 diffuseColor = vec4(color, opacity);
      `
      );
    };
  }

  area() {
    this.list.area.onBeforeCompile = (shader) => {
      shader.uniforms.uTime = this.uniforms.uTime;
      shader.uniforms.uSpriteFade = this.uniforms.uSpriteFade;

      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <common>",
        `
          #include <common>
          uniform float uSpriteFade;

          float pulse(float d){
            return (abs(sin(-d * 5.0 + uTime * 1.5)) - (d - 0.25) * 4.0) * 0.5;
          }

          float blur0_25(float d){
            return max(d > 0.22 ? 1.0 - ((d - 0.22) * 33.33) : 1.0, 0.0);
          }
        `
      );
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <fog_fragment>",
        `
          #include <fog_fragment>
          float d = distance(vMapUv, vec2(0.5));
          gl_FragColor = vec4(vec3(1.0), (d < 0.5 ? blur0_25(d) + pulse(d) : 0.0) * (1.0 - uSpriteFade));
        `
      );
    };
  }

  subArea(mappingKey, currentSprite, activated) {
    const mat = this.list.subArea.clone();
    mat.onBeforeCompile = (shader) => {
      shader.uniforms.uTime = this.uniforms.uTime;
      shader.uniforms.uMappingKey = { value: mappingKey };
      shader.uniforms.uCurrentSprite = currentSprite;
      shader.uniforms.uSpriteFade = this.uniforms.uSpriteFade;
      shader.uniforms.uActivated = activated;

      shader.fragmentShader = shader.fragmentShader.replace(
          "#include <common>",
          `
        #include <common>

        uniform float uCurrentSprite;
        uniform float uSpriteFade;
        uniform float uMappingKey;
        uniform float uActivated;

        // Simplifier la fonction de pulsation
        float pulse(float d){
          return (sin(-d * 5.0 + uTime) * 0.25) * max(0.25 - d, 0.0);
        }

        float blur0_25(float d){
          return max(d > 0.22 ? 1.0 - ((d - 0.22) * 33.33) : 1.0, 0.0);
        }

        float blur0_50(float d){
          return max(d > 0.47 ? 1.0 - ((d - 0.47) * 33.33) : 1.0, 0.0);
        }
      `
      );
      shader.fragmentShader = shader.fragmentShader.replace(
          "#include <fog_fragment>",
          `
        #include <fog_fragment>
        float d = distance(vMapUv, vec2(0.5));
        
        // Simplification: éviter les calculs pour les pixels hors du cercle
        if(d >= 0.5) {
          discard;
        }

        if (uActivated < 0.5) {
          gl_FragColor = vec4(vec3(1.0), (blur0_25(d) + pulse(d)));
        } else if (abs(uMappingKey - uCurrentSprite) < 0.1) {
          if (d < 0.25) {
            gl_FragColor = vec4(0.15, 0.4, 0.76, 1.0) * blur0_25(d);
          } else {
            gl_FragColor = vec4(blur0_50(d));
          }
        } else {
          gl_FragColor = vec4(0.0);
        }
        
        gl_FragColor.a *= uSpriteFade;
      `
      );
    };
    return mat;
  }

  // Mise à jour de la méthode radioWaves dans Materials.js
// Mise à jour finale de la méthode radioWaves dans Materials.js
  radioWaves() {
    // Create ShaderMaterial for radio waves with randomized start time
    this.list.radioWaves = new THREE.ShaderMaterial({
      transparent: true,
      depthWrite: false,
      side: THREE.DoubleSide,
      uniforms: {
        uTime: this.uniforms.uTime,
        uColor: { value: new THREE.Color(0xff3333) },  // Red color for waves
        uOrigin: { value: new THREE.Vector3(0, 0, 0) }, // Origin point of waves
        uSpeed: { value: 2.0 },
        uFrequency: { value: 5.0 },
        uWaveSpacing: { value: 0.5 }, // Espacement entre les deux ondes
        uIntensity: { value: 5.0 },
        uFadeRate: { value: 0.2 },
        uMaxDistance: { value: 0.33 },
        uFadeStart: { value: 0.15 },  // Fade starts at 33%
        uCycleLength: { value: 8.0 }, // Longueur totale du cycle
        uActivePeriod: { value: 2.0 },  // Période active où on génère les ondes
        uStartOffset: { value: Math.random() * 10.0 }  // Random offset for initialization
      },
      vertexShader: `
  uniform float uTime;
  uniform vec3 uOrigin;
  
  varying vec3 vWorldPos;
  varying vec2 vUv;
  
  void main() {
    vUv = uv;
    
    // Calculate world space position
    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
    vWorldPos = worldPosition.xyz;
    
    gl_Position = projectionMatrix * viewMatrix * worldPosition;
  }
`,
      fragmentShader: `
  uniform float uTime;
  uniform vec3 uOrigin;
  uniform vec3 uColor;
  uniform float uSpeed;
  uniform float uFrequency;
  uniform float uWaveSpacing;
  uniform float uIntensity;
  uniform float uFadeRate;
  uniform float uMaxDistance;
  uniform float uFadeStart;
  uniform float uCycleLength;
  uniform float uActivePeriod;
  uniform float uStartOffset;
  
  varying vec3 vWorldPos;
  varying vec2 vUv;
  
  void main() {
    // Distance à l'émetteur dans le plan xz
    float dist = length(vWorldPos - uOrigin);
    
    // Distance normalisée (0 à 1)
    float distanceRatio = dist / uMaxDistance;
    
    // Add the random offset to time to get different starting points
    float offsetTime = uTime * uSpeed + uStartOffset;
    
    // Calculer dans quel cycle on se trouve
    float cycleNumber = floor(offsetTime / uCycleLength);
    
    // Temps écoulé depuis le début du cycle actuel
    float cycleTime = offsetTime - cycleNumber * uCycleLength;
    
    // Temps de début effectif pour chaque cycle - quand la période active commence
    float effectiveStartTime = cycleNumber * uCycleLength;
    
    // Générer exactement 2 ondes dans la période active
    // Première onde au tout début de la période active
    float waveTime1 = effectiveStartTime;
    // Deuxième onde un peu après
    float waveTime2 = effectiveStartTime + uWaveSpacing;
    
    // Calculer la distance de propagation pour chaque onde
    float waveRadius1 = (offsetTime - waveTime1) * 0.2;
    float waveRadius2 = (offsetTime - waveTime2) * 0.2;
    
    // Vérifier si les ondes sont actives (générées pendant la période active)
    bool wave1Active = cycleTime >= 0.0 && cycleTime <= uActivePeriod;
    bool wave2Active = cycleTime >= uWaveSpacing && cycleTime <= uActivePeriod;
    
    // Calculer l'intensité de chaque onde
    float wave1 = 0.0;
    float wave2 = 0.0;
    
    if (waveRadius1 > 0.0) {
      // Largeur de l'onde
      float thickness = 0.02;
      
      // Calculer l'intensité basée sur la distance au cercle de l'onde
      float ringDistance1 = abs(dist - waveRadius1);
      
      // Former l'anneau avec une atténuation gaussienne
      wave1 = exp(-ringDistance1 * ringDistance1 / (thickness * thickness));
      
      // Appliquer l'atténuation basée sur la distance de propagation
      float propagationAttenuation1 = max(0.0, 1.0 - (waveRadius1 / (uMaxDistance * 2.0)));
      wave1 *= propagationAttenuation1 * propagationAttenuation1; // Atténuation quadratique
    }
    
    if (waveRadius2 > 0.0) {
      float thickness = 0.02;
      float ringDistance2 = abs(dist - waveRadius2);
      wave2 = exp(-ringDistance2 * ringDistance2 / (thickness * thickness));
      
      float propagationAttenuation2 = max(0.0, 1.0 - (waveRadius2 / (uMaxDistance * 2.0)));
      wave2 *= propagationAttenuation2 * propagationAttenuation2;
    }
    
    // Combiner les ondes
    float combinedWave = max(wave1, wave2);
    
    // Appliquer un seuil minimum pour éviter des calculs sur des pixels presque invisibles
    if (combinedWave < 0.01) {
      discard;
      return;
    }
    
    // Calculer l'alpha finale
    float alpha = combinedWave;
    
    // Résultat final
    gl_FragColor = vec4(uColor, alpha);
  }
`
    });
  }
// Fix for Materials.js - droneWaves method
  // Dans Materials.js, remplacer la méthode droneWaves par celle-ci
// Dans Materials.js, remplacer la méthode droneWaves par celle-ci
  // Remplacer la méthode droneWaves dans Materials.js
// Mise à jour de la méthode droneWaves dans Materials.js
// Remplacer la méthode droneWaves dans Materials.js pour ajouter plus d'ondes
  droneWaves() {
    // Create ShaderMaterial for drone scan waves that descend from top to bottom
    this.list.droneWaves = new THREE.ShaderMaterial({
      transparent: true,
      depthWrite: false,
      side: THREE.DoubleSide,
      uniforms: {
        uTime: this.uniforms.uTime,
        uColor: { value: new THREE.Color(0xff3333) },  // Couleur rouge pour les ondes
        uBaseColor: { value: new THREE.Color(0xff0000) }, // Couleur rougeâtre pour l'intervalle entre les scans
        uOrigin: { value: new THREE.Vector3(0, 0, 0) }, // Point d'origine des ondes (non utilisé)
        uSpeed: { value: 0.25 },           // Vitesse de descente ralentie
        uWaveCount: { value: 12.0 },       // Augmenté le nombre d'ondes visibles simultanément
        uWaveThickness: { value: 0.006 },  // Épaisseur encore plus fine pour les lignes
        uConeHeight: { value: 275.0 },     // Hauteur du cône
        uConeAngle: { value: 3.0 },        // Angle du cône en degrés
        uIntensity: { value: 1.0 },        // Intensité des ondes
        uBaseOpacity: { value: 0.1 }       // Opacité de la couleur de base (10%)
      },
      vertexShader: `
    uniform float uTime;
    
    varying vec3 vPosition;
    varying vec2 vUv;
    
    void main() {
      vUv = uv;
      vPosition = position;
      
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
      fragmentShader: `
    uniform float uTime;
    uniform vec3 uColor;
    uniform vec3 uBaseColor;
    uniform float uSpeed;
    uniform float uWaveCount;
    uniform float uWaveThickness;
    uniform float uConeHeight;
    uniform float uConeAngle;
    uniform float uIntensity;
    uniform float uBaseOpacity;
    
    varying vec3 vPosition;
    varying vec2 vUv;
    
    void main() {
      // Normalize position for cone height (0 = top, 1 = bottom)
      // Inversion de la coordonnée Y car dans Three.js la base du cône est à Y=0 et la pointe à Y=height
      float heightRatio = 1.0 - (vPosition.y / uConeHeight);
      
      // Calculate waves that continuously descend
      float totalWave = 0.0;
      
      // Générer de multiples ondes en continu - augmenté pour plus d'ondes
      for(int i = 0; i < 12; i++) {
        // Espace les ondes régulièrement
        float waveOffset = float(i) / 12.0;
        
        // Position de cette onde (0 à 1, où elle se trouve le long de la hauteur du cône)
        float wavePos = fract(uTime * uSpeed + waveOffset);
        
        // Assurer que les ondes descendent complètement jusqu'en bas
        // en démarrant juste avant le sommet et en continuant un peu après la base
        wavePos = wavePos * 1.05; 
        
        // Distance entre la position actuelle et la position de l'onde
        float distToWave = abs(heightRatio - wavePos);
        
        // Créer une ligne fine avec une épaisseur constante pour un effet de scan précis
        float lineThickness = uWaveThickness; // Épaisseur constante pour des lignes parfaitement uniformes
        
        // Utilisation de smoothstep pour créer des bords adoucis mais des lignes fines
        float wave = smoothstep(lineThickness, 0.0, distToWave);
        
        // Les ondes conservent leur intensité constante pendant la descente
        float waveStrength = 1.0;
        
        // Ajouter cette onde au total
        totalWave += wave * waveStrength;
      }
      
      // Appliquer une atténuation radiale - plus forte au centre, plus faible aux bords
      float distFromCenter = length(vec2(vPosition.x, vPosition.z));
      float maxRadius = uConeHeight * tan(radians(uConeAngle * 0.5)) * heightRatio;
      float radialRatio = distFromCenter / (maxRadius + 0.001); // Éviter la division par zéro
      float radialFalloff = 1.0 - pow(radialRatio, 1.5); // Atténuation moins agressive
      
      // Couleur de base rouge à faible opacité pour l'intervalle entre les scans
      vec3 baseColor = uBaseColor;
      float baseAlpha = uBaseOpacity * radialFalloff;
      
      // Combiner pour intensité finale des ondes
      float waveIntensity = min(totalWave * radialFalloff * uIntensity, 1.0);
      
      // Appliquer un seuil minimum pour la performance (seulement si pas de couleur de base)
      if (waveIntensity < 0.01 && baseAlpha < 0.01) {
        discard;
        return;
      }
      
      // Mélanger la couleur des ondes et la couleur de base
      vec3 finalColor;
      float finalAlpha;
      
      if (waveIntensity > 0.05) {
        // Si une onde est visible, utiliser sa couleur et son intensité
        finalColor = uColor;
        finalAlpha = waveIntensity * (radialFalloff * 0.7 + 0.3);
      } else {
        // Sinon, utiliser la couleur de base avec faible opacité
        finalColor = baseColor;
        finalAlpha = baseAlpha;
      }
      
      gl_FragColor = vec4(finalColor, finalAlpha);
    }
  `
    });

    return this.list.droneWaves;
  }

  update(deltaTime) {
    // Update uniform time
    this.uniforms.uTime.value += deltaTime;

    // If you have radio waves active, update their time as well
    if (this.list.radioWaves) {
      this.list.radioWaves.uniforms.uTime.value = this.uniforms.uTime.value;
    }
    if (this.list.droneScan) {
      this.list.droneScan.uniforms.uTime.value = this.uniforms.uTime.value;
    }
  }

}